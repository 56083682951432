import './App.css';
import React, { useEffect, useState } from 'react';
import { Routes, Route, useSearchParams } from 'react-router-dom';
import Login from "./components/Login";
import { getChallengeCodes, authenticated } from './utils/Auth';
import Layout from './components/Layout';
import { Dashboard } from './components/Dashboard';
import Policies from './components/Policies';
import Lists from './components/Lists';
import Analyzers from './components/Analyzers';
import Sites from './components/Sites';
import { Settings } from './components/Settings';
import Logout from './components/Logout';
import { NoMatch } from "./components/NoMatch";
import { ToastContainer } from 'react-toastify';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { AppBar, Toolbar, Box, makeStyles, Typography, Dialog } from "@material-ui/core"
import { CssBaseline, IconButton, Stack } from '@mui/material'
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import Logs from './components/Logs'; // Import the Logs component

const drawerWidth = 180;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
    },
    paper: {
        width: drawerWidth,
        backgroundColor: '#181818',
    },
    appbar: {
        width: '100%',
        elevation: '5',
        zIndex: theme.zIndex.drawer + 1
    },
    toolbar: {
        height: '75px',
    },
    logoDiv: {
      display: 'flex',
      width: '500px',
    },
    profileBar: {
        display: 'flex',
        marginLeft: 'auto',
        alignItems: 'center',
        padding: '10px',
    },
    navLabel: {
        fontWeight: 'normal',
        textDecoration: 'none',
        fontSize: '1.4rem',
        alignItems: 'center',
        justifyContent: 'center',
        lineHeight: '20px',
        display: 'flex',
        padding: '10px',
    },
    navDiv: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '10px',
        '&:hover': {
            background: "#222",
        },
    },
    divider: {
      background: '#bbbbbb',
    },
    versionLabel: {
        padding: '10px',
        top: '20px',
        color: '#5e5e5e',
    },
    spaced: {
        margin: '10px',
    },
    greeting: {
        width: 500,
        height: 200,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#5e5e5e',
        color: '#bbbbbb',
    },
}));

function App() {
    const classes = useStyles();
    const [searchParams] = useSearchParams(); // Remove setSearchParams
    const [href, setHref] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(authenticated());
    const theme = createTheme({
        palette: {
            mode: 'dark',
            secondary: {
                main: '#d67404',
            },
        },
    });

    useEffect(() => {
        if (!searchParams.get('code') && !searchParams.get('state')) {
            getChallengeCodes(setHref);
        }
    }, [isAuthenticated, searchParams]); // Add searchParams to dependency array

    if (isAuthenticated) {
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline enableColorScheme />
                <Layout>
                    <ToastContainer />
                    <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="policies" element={<Policies />} />
                        <Route path="lists" element={<Lists />} />
                        <Route path="analyzers" element={<Analyzers />} />
                        <Route path="logs" element={<Logs />} />
                        <Route path="sites" element={<Sites />} />
                        <Route path="settings" element={<Settings />} />
                        <Route path="logout" element={<Logout />} />
                        <Route path="*" element={<NoMatch />} />
                    </Routes>
                </Layout>
            </ThemeProvider>
        );
    } else {
        if (window.location.pathname !== '/login/') {
            sessionStorage.setItem('beforeLogin', window.location.pathname);
        }
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline enableColorScheme />
                <Routes>
                    <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
                    <Route path="/logout" element={<Logout setIsAuthenticated={setIsAuthenticated} />} />
                </Routes>
                <div id="root" className={classes.root}>
                    <AppBar position="fixed" className={classes.appbar}>
                        <Toolbar className={classes.toolbar}>
                            <Box className={classes.logoDiv}>
                                <img src="/red-logo_white-text.png" alt="logo" height="45px" />
                            </Box>
                            <Box className={classes.profileBar}>
                                <IconButton size="large"><HelpOutlineRoundedIcon /></IconButton>
                                <a href={localStorage.getItem('href')} ><PowerSettingsNewOutlinedIcon color="error" /></a>
                            </Box>
                        </Toolbar>
                    </AppBar>
                    <Dialog open={true}>
                        <Stack className={classes.greeting}>
                            <Typography variant="h5">Welcome to RansomStop Admin Portal</Typography>
                            <br />
                            <Typography variant="h5">Please Log In</Typography>
                            <br />
                            <a href={href}><PowerSettingsNewOutlinedIcon color="info" /></a>
                        </Stack>
                    </Dialog>
                </div>
            </ThemeProvider>
        );
    }
}

export default App;
