import { makeStyles } from '@material-ui/core'
import PerformanceCard from './PerformanceCard';
import { Paper } from "@mui/material";
import AlertCard from "./AlertCard";

const useStyles = makeStyles(theme => ({
    outer_div: {
        //minHeight: '100vh',
    },
    paper: {
        backgroundColor: '#181818',
    },
    card: {
        backgroundColor: '#181818',
    },
}))

export const Dashboard = () => {
    const classes = useStyles()

    return (
        <>
            <div className={classes.outer_div} style={{padding: '20px'}}>
                <Paper className={classes.card} variant="outlined" style={{padding: '15px'}}>
                    <AlertCard></AlertCard>
                </Paper>
            </div>
            <div className={classes.outer_div} style={{padding: '20px'}}>
                <PerformanceCard></PerformanceCard>
            </div>
        </>
    )
}