import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Constants from "../utils/Constants";
import { getAccessToken } from "../utils/Auth";
import Notification from "./Notification"
import { formatDate, isHidden, getDetail, epochSeconds } from "../utils/Functions";
import { Paper } from '@mui/material'
import { Box, Select, MenuItem, Typography, makeStyles } from '@material-ui/core'
import { Stack, IconButton } from "@mui/material"
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ColumnsDialog from "./ColumnsDialog";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import AnalyzersDialog from "./AnalyzersDialog";
import DeleteDialog from "./DeleteDialog";

let http_str = "https://"
let base_url = http_str.concat(Constants.apiHost, '.', Constants.domainName,'/api/v1/analyzers')
let sites_url = http_str.concat(Constants.apiHost, '.', Constants.domainName,'/api/v1/sites')
let policies_url = http_str.concat(Constants.apiHost, '.', Constants.domainName,'/api/v1/policies')


const useStyles = makeStyles(theme => ({
    outer_div: {
        minHeight: '100vh',
    },
    card: {
        backgroundColor: '#424242',
        color: '#EEEEEE',
    },
    itemBar: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#222222',
    },
    pageBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        backgroundColor: '#222222',
        color: '#AAAAAA',
    },
    tableComponent: {
        borderStyle: 'solid',
        borderColor: '#000',
        borderRadius: '10px',
        borderSpacing: '0px',
        borderRightWidth: '0px',
        borderTopWidth: '1px',
        borderBottomWidth: '0px',
        borderLeftWidth: '0px',
        // backgroundColor: '#222222' // setting this breaks row highlighting
    },
    tableCell: {
        paddingLeft: '12px',
    },
    clickableTableCell: {
        paddingLeft: '12px',
        cursor: 'pointer',
    },
    dialog: {
        backgroundColor: '#333333',
        color: '#CCCCCC',
        margin: '10',
    },
    dialogTitleBar: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
    },
    dialogConfirm: {
        backgroundColor: '#149616',
        color: '#CCCCCC',
    },
    dialogNeutral: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
    },
    dialogWarning: {
        backgroundColor: '#d42842',
        color: '#CCCCCC',
    },
    primaryColor: {
        color: '#000',
        backgroundColor: '#111'
    },
    whiteColor: {
        color: "#EEE",
    },
    hide: {
        display: 'none',
    },
    spaced: {
        margin: '10px',
    }
}))

let headers = [
    { field: 'pk', headerName: 'Name', width: 300, hidden: false },
    { field: 'site_uuid', headerName: 'Site', width: 100, hidden: false },
    { field: 'region', headerName: 'Region', width: 150, hidden: false },
    { field: 'policy_uuid', headerName: 'Policy', width: 200, hidden: false },
    { field: 'log_level', headerName: 'Log Level', width: 50, hidden: false },
    { field: 'created', headerName: 'Created', width: 220, hidden: false},
    { field: 'modified', headerName: 'Modified', width: 220, hidden: false},
    { field: 'edit', headerName: 'Edit', width: 50, hidden: false },
    { field: 'delete', headerName: 'Delete', width: 50, hidden: false },
]

export default function Lists() {
    const classes = useStyles()
    const [items, setItems] = useState([])
    const [selectedItem, setSelectedItem] = useState(null)
    const [selectedRow, setSelectedRow] = useState(null)
    const [columnsMode, setColumnsMode] = useState(false)
    const [addMode, setAddMode] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [deleteMode, setDeleteMode] = useState(false)
    const [limit, setLimit] = React.useState(10)
    const [pages, setPages] = useState([0])
    const [pageIndex, setPageIndex] = useState(0)
    const [siteData, setSiteData] = useState([])
    const [policyData, setPolicyData] = useState([])
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const getItems = async () => {
        //const session = await fetchAuthSession();
        let url = base_url
        if (pageIndex !== 0) {
            console.log('fetch page: ' + pages[pageIndex])
            url = url + '?limit=' + limit + '&nextPageKey=' + pages[pageIndex]
        }
        else {
            console.log('fetch page: 0')
            url = url + '?limit=' + limit
        }
        const http_headers = { "Content-Type": "application/json","Authorization": await getAccessToken() }
        console.log('url: ' + url)
        await axios
            .get(url, { headers: http_headers })
            .then(res => {
                if (pageIndex === pages.length - 1) {
                    let npk = res.data['nextPageKey']
                    if (npk) {
                        let tmp_arr = pages
                        tmp_arr.push(npk)
                        setPages(tmp_arr)
                    }
                }
                const items = res.data['analyzers'];
                console.log(items);
                setItems(items)
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + err.response)
                } else if (err.request) {
                  console.log('no response from server: ' + err.request)
                } else {
                  console.log(err)
                }
                Notification(`Error while fetching ${url}. ${err}`, 'error');
                return Promise.reject(err);
            })
    }


    const addItem = async (item) => {
        let url = base_url
        const http_headers = { 'Content-Type': 'application/json','Authorization': await getAccessToken() }
        let params = {
            pk: item.pk,
            region: item.region,
            size: item.size,
            site_uuid: item.site_uuid,
            policy_uuid: item.policy_uuid,
            policy_interval: item.policy_interval,
            log_level: item.log_level,
            log_interval: item.log_interval,
            monitored_storage_type: item.monitored_storage_type,
            send_alerts: item.send_alerts,
            alert_type: item.alert_type,
            sns_topic_arn: item.sns_topic_arn,
            monitored_storage_location: item.monitored_storage_location,
            sqs_queue: item.sqs_queue,
            suspend_user_policy: item.suspend_user_policy,
            policies_deployed: false,
            vpc_id: item.vpc_id,
            vpc_name: item.vpc_name,
            subnets: item.subnets,
        }
        console.log('add analyzer: ' + JSON.stringify(params))
        await axios
            .post(url, { 'analyzer': params },{ headers: http_headers })
            .then(res => {
                console.log(res.data);
                // Add the data submitted back to the local table so it is correct before server side refresh
                params.pk = res.data['pk']
                const epoch = epochSeconds()
                params.created = epoch
                params.modified = epoch
                setItems(items.concat([params]))
                Notification(res.data['msg'], 'info');
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + err.response)
                } else if (err.request) {
                  console.log('no response from server: ' + err)
                } else {
                  console.log(err)
                }
                Notification(`Error: adding new site. ${err}`, 'error');
            })
    }

    const editItem = async (item) => {
        let url = base_url + "/" + item.pk
        const http_headers = { 'Content-Type': 'application/json','Authorization': await getAccessToken() }
        let params = {
            pk: item.pk,
            region: item.region,
            size: item.size,
            site_uuid: item.site_uuid,
            policy_uuid: item.policy_uuid,
            policy_interval: item.policy_interval,
            log_level: item.log_level,
            log_interval: item.log_interval,
            monitored_storage_type: item.monitored_storage_type,
            send_alerts: item.send_alerts,
            alert_type: item.alert_type,
            sns_topic_arn: item.sns_topic_arn,
            monitored_storage_location: item.monitored_storage_location,
            sqs_queue: item.sqs_queue,
            suspend_user_policy: item.suspend_user_policy,
            policies_deployed: item.policies_deployed,
            vpc_id: item.vpc_id,
            vpc_name: item.vpc_name,
            subnets: item.subnets,
        }
        await axios
            .put(url, { 'analyzer': params },{ headers: http_headers })
            .then(res => {
                console.log(res.data);
                // Add the data submitted back to the local table
                // setItems(items.concat([params]))
                // @ ToDo need to do setItems here and update the object so it refreshes w/o needing server refresh
                Notification(res.data['msg'], 'info');
                forceUpdate()
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + err.response)
                } else if (err.request) {
                  console.log('no response from server: ' + err)
                } else {
                  console.log(err)
                }
                Notification(`Error updating analyzer. ${err}`, 'error');
            })
    }

    const deleteItem = async (uuid) => {
        //const session = await fetchAuthSession();
        let url = base_url + "/" + uuid
        const headers = { 'Content-Type': 'application/json','Authorization': await getAccessToken() }
        await axios
            .delete(url, { headers: headers })
            .then(res => {
                console.log(res.data);
                let items_filtered = items.map((x) => x)
                items_filtered = items_filtered.filter(i => i.pk !== uuid)
                setItems(items_filtered)
                Notification(res.data, 'info');
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + err.response)
                } else if (err.request) {
                  console.log('no response from server: ' + err)
                } else {
                  console.log(err)
                }
                Notification(`Error: deleting ${uuid}. ${err}`, 'error');
                return Promise.reject(err);
            })
    }

    const getSites = async () => {
        let url = sites_url + '?limit=100'
        const http_headers = { "Content-Type": "application/json","Authorization": await getAccessToken() }
        console.log('url: ' + url)
        await axios
            .get(url, { headers: http_headers })
            .then(res => {
                const items = res.data['sites'];
                setSiteData(items)
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + err.response)
                } else if (err.request) {
                  console.log('no response from server: ' + err.request)
                } else {
                  console.log(err)
                }
                Notification(`Error while fetching ${url}. ${err}`, 'error');
            })
    }

    const getPolicies = async () => {
        let url = policies_url + '?limit=25'
        const http_headers = { "Content-Type": "application/json","Authorization": await getAccessToken() }
        console.log('url: ' + url)
        // console.log('headers: ' + JSON.stringify(http_headers))
        await axios
            .get(url, { headers: http_headers })
            .then(res => {
                const items = res.data['policies'];
                console.log(items);
                items.unshift({pk: '0', name: ''})
                setPolicyData(items)
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + err.response)
                } else if (err.request) {
                  console.log('no response from server: ' + err.request)
                } else {
                  console.log(err)
                }
                Notification(`Error while fetching ${url}. ${err}`, 'error');
            })
    }

    // PrefetchSelectedRow will fetch list details every row you click
    // It should make loading the edit screen faster, but it might add a lot more API calls
    const prefetchSelectedRow = (uuid) => {
        if (selectedRow === uuid) {
            // deselect a row if it's already selected
            setSelectedRow(null)
        }
        else {
            setSelectedRow(uuid)
            setSelectedItem(getItemByPk('items', uuid))
            //getDetail(uuid)
        }
    }

    function getItemByPk(table, pk) {
        // might have over-engineered this, so far only needs items case
        switch (table) {
            case 'items':
                return items.find(i => i.pk === pk)
            case 'sites':
                return siteData.find(i => i.pk === pk)
            case 'policies':
                return policyData.find(i => i.pk === pk)
            default:
                return null
        }
    }

    function getNameByPk(table, pk) {
        switch (table) {
            case 'site':
                let site = siteData.find(i => i.pk === pk)
                // console.log('siteData: ' + JSON.stringify(siteData))
                // console.log('found: ' + JSON.stringify(found))
                if (site) {
                    return site['site_name']
                }
                break
            case 'policy':
                let policy = policyData.find(i => i.pk === pk)
                if (policy) {
                    return policy['policy_name']
                }
                break
            default:
                return null
        }
    }

    const handleOpenColumns = () => {
        setColumnsMode(true);
    };

    const handleCloseColumns = () => {
        setColumnsMode(false);
    };

    const handleOpenAdd = () => {
        setAddMode(true);
        console.log("add new analyzer")
    };

     const handleAdd = (fields) => {
        let addData = addItem(fields)
        // check addData for error and notify user
         // not closing dialog so that users can deploy without editing object again
        // setAddMode(false)
    }

    const handleCloseAdd = () => {
        setAddMode(false);
    };

    const handleOpenEdit = (e, uuid) => {
        setSelectedRow(uuid)
        console.log("edit " + selectedRow)
        getDetail(base_url, 'analyzer', uuid, setSelectedItem, setEditMode)
    };

    const handleEdit = (fields) => {
        let editData = editItem(fields)
        // check editData for error and notify user
        // not closing dialog so that users can deploy without editing object again
        // setEditMode(false)
    }

    const handleCloseEdit = () => {
        setEditMode(false);
    };

    const handleOpenDelete = (e, uuid) => {
        // stopPropagation seems to block selecting and editing a row in 1 click, so remove it if it doesn't cause any other issues
        //e.stopPropagation()
        setSelectedRow(uuid)
        setDeleteMode(true);
        console.log("delete " + selectedRow)
    };

    const handleDelete = (uuid) => {
        deleteItem(uuid)
        setDeleteMode(false);
    };

    const handleCloseDelete = () => {
        setDeleteMode(false);
    };

    const handleLimit = (event) => {
        setLimit(event.target.value);
        setPages([0])
        setPageIndex(0)
    };

    const handlePreviousPage = () => {
        if (pageIndex > 0) {
            setPageIndex(pageIndex - 1)
        }
    }

    const handleNextPage = () => {
        if (pageIndex < pages.length - 1) {
            setPageIndex(pageIndex + 1)
        }
    }

    const toggleColumn = (event) => {
        for (let i = 0; i < headers.length; i++) {
            if (headers[i]['field'] === event.target.id) {
                headers[i]['hidden'] = !headers[i]['hidden']
            }
        }
        //  ugly but since the checkboxes aren't tied to state, they are tied to column definitions, they don't update
        forceUpdate()
    };

    useEffect(() => {
        getItems()
        getSites()
    }, []);

    useEffect(() => {
        getPolicies()
    }, [pageIndex, limit] )

    return (
        <div className={classes.outer_div} style={{padding: '20px'}}>
            <Paper className={classes.card} variant="outlined" style={{padding: '15px'}}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="h4" >Analyzers</Typography>
                    <Box>
                        <ViewWeekOutlinedIcon className={classes.spaced} color={"primary"}
                                              onClick={() => handleOpenColumns()}/>
                        <AddOutlinedIcon className={classes.spaced} color={"primary"}
                                         onClick={() => handleOpenAdd()}/>
                    </Box>
                </Stack>
                {/* TABLE */}
                <table className={classes.tableComponent} style={{width: 100 + "%"}} >
                    <thead>
                        <tr className={classes.tableComponent} >
                            {headers.map(header => (
                                <th width={header.width} className={ header.hidden === true ? classes.hide: '' }>{header.headerName}</th>
                                ))}
                        </tr>
                    </thead>
                    <tbody  className={classes.tableComponent} >
                        {items.map(val =>
                            <tr key={val.pk} onClick={() => prefetchSelectedRow(val.pk)}
                                bgcolor={selectedRow === val.pk ? '#242c42' : '#222222'}
                                className={classes.tableComponent}>
                                <td className={isHidden('pk', headers) ? classes.hide : classes.tableCell}>{val.pk}</td>
                                <td className={isHidden('site_uuid', headers) ? classes.hide : classes.tableCell}>{getNameByPk('site', val.site_uuid)}</td>
                                <td className={isHidden('region', headers) ? classes.hide : classes.tableCell}>{val.region}</td>
                                <td className={isHidden('policy_uuid', headers) ? classes.hide : classes.tableCell}>{getNameByPk('policy', val.policy_uuid)}</td>
                                <td className={isHidden('log_level', headers) ? classes.hide : classes.tableCell}>{val.log_level}</td>
                                <td className={isHidden('created', headers) ? classes.hide : classes.tableCell}>{formatDate(val.created)}</td>
                                <td className={isHidden('modified', headers) ? classes.hide : classes.tableCell}>{formatDate(val.modified)}</td>
                                <td className={isHidden('edit', headers) ? classes.hide : classes.clickableTableCell}>
                                    <EditOutlinedIcon color={"primary"} onClick={(e) => handleOpenEdit(e, val.pk)}/></td>
                                <td className={isHidden('delete', headers) ? classes.hide : classes.clickableTableCell}>
                                    <BackspaceOutlinedIcon color={"error"} onClick={(e) => handleOpenDelete(e, val.pk)}/>
                                </td>
                            </tr>
                            )}
                    </tbody>
                </table>
                <Paper className={classes.itemBar} elevation={1}>
                    <Stack className={classes.pageBar}
                        elevation={0}
                        direction="row"
                        alignItems="center"
                        sx={{ width: 1, height: "5vh" }} >
                            <IconButton onClick={() => getItems()}><RefreshOutlinedIcon className={classes.spaced} /></IconButton>
                            <Typography className={classes.spaced}>Rows per page:</Typography>
                            <Select
                                classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                                labelId="fetch-limit-select"
                                id="fetch-limit-select"
                                value={limit}
                                label="Limit"
                                onChange={handleLimit}
                            >
                              <MenuItem value={10}>10</MenuItem>
                              <MenuItem value={25}>25</MenuItem>
                              <MenuItem value={50}>50</MenuItem>
                            </Select>
                            <IconButton disabled={pageIndex === 0} onClick={() => handlePreviousPage()}><ChevronLeftOutlinedIcon /></IconButton>
                            <Typography variant={'h6'}>{pageIndex + 1}</Typography>
                            <IconButton disabled={pageIndex >= pages.length - 1} onClick={() => handleNextPage()}><ChevronRightOutlinedIcon /></IconButton>
                    </Stack>
                </Paper>

                {/* DIALOGS */}
                {/* COLUMNS DIALOG */}
                <ColumnsDialog
                    variant={"outlined"}
                    open={columnsMode}
                    onClose={handleCloseColumns}
                    onChange={toggleColumn}
                    headers={headers}
                />

                {/* ADD DIALOG */}
                <AnalyzersDialog
                    mode={'add'}
                    open={addMode}
                    onClose={handleCloseAdd}
                    onSave={handleAdd}
                    item={null}
                    siteData={siteData}
                    policyData={policyData}
                />

                {/* EDIT DIALOG */}
                <AnalyzersDialog
                    mode={'edit'}
                    open={editMode}
                    onClose={handleCloseEdit}
                    onSave={handleEdit}
                    item={selectedItem}
                    siteData={siteData}
                    policyData={policyData}
                />

                {/* DELETE DIALOG */}
                <DeleteDialog
                    open={deleteMode}
                    onClose={handleCloseDelete}
                    onDelete={handleDelete}
                    type={'analyzer'}
                    row={selectedRow}
                    item={selectedItem}
                />
            </Paper>
        </div>
    )
}