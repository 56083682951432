import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export default function Notification(message, type = "info") {
  return toast[type](message, {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  })
}
