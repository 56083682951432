const Constants = {

  applicationName: 'RansomStop',

  customerName: 'ahoyvey',
  apiHost: 'ahoyvey-api',
  domainName: 'ransomstop.net',
  version: '24.7.30',
  userPoolId: 'us-east-1_pxySb8gVM',
  userPoolClientId: '6du9qohtue181eu9oqacckv28n',
  authDomain: 'ahoyvey-ransomstop-net.auth.us-east-1.amazoncognito.com',
  region: 'us-east-1',
  invokeUrl: '',

}
export default Constants