import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AddOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import { IconButton } from "@mui/material";

const useStyles = makeStyles(theme => ({
    card: {
        backgroundColor: '#424242',
        color: '#EEEEEE',
    },
    tableComponent: {
        borderStyle: 'solid',
        borderColor: '#000',
        borderRadius: '10px',
        borderSpacing: '0px',
        borderRightWidth: '0px',
        borderTopWidth: '0px',
        borderBottomWidth: '0px',
        borderLeftWidth: '0px',
        // backgroundColor: '#222222' // setting this breaks row highlighting, leaving this as a warning
    },
    tableCell: {
        paddingLeft: '12px',
    },
    primaryColor: {
        color: '#000',
        backgroundColor: '#111'
    },
    whiteColor: {
        color: "#EEE",
    },
    hide: {
        display: 'none',
    },
    spaced: {
        margin: '10px',
    }
}))


export default function SimpleTable({ headers, item, handleCellChange }) {
    const classes = useStyles()
    const [selectedRow, setSelectedRow] = useState(null)
    const [version, setVersion] = useState(0)

    const prefetchSelectedRow = (ext) => {
        if (selectedRow === ext) {
            // deselect a row if it's already selected
            setSelectedRow(null)
        }
        else {
            setSelectedRow(ext)
        }
    }

    const handleAddRow = () => {
        let new_entry = {'new': { description: '' }}
        item['list_data'] = Object.assign(new_entry, item['list_data'])
        // table doesn't update without a state change
        setVersion(version + 1)
    }

    const handleDeleteRow = (extension) => {
        if (extension in item['list_data']) {
            delete item['list_data'][extension]
        }
        // table doesn't update without a state change
        setVersion(version + 1)
    }

    useEffect(() => {
        console.log('headers: ' + JSON.stringify(headers))
        console.log('item: ' + JSON.stringify(item))
    }, []);

    return (
        <table className={classes.tableComponent} style={{width: 100 + "%"}} >
            <thead>
            <tr key='1' className={classes.tableComponent}>
                <th>{headers[0]}</th>
                <th>{headers[1]}</th>
                <th width={"50px"}>
                    <IconButton onClick={() => handleAddRow()}>
                        <AddOutlinedIcon color={"primary"} color={"primary"}/>
                    </IconButton>
                </th>
            </tr>
        </thead>
        <tbody className={classes.tableComponent}>
            {item['list_data'] && Object.keys(item['list_data']).map(( key, val ) => (
                <tr key={key} onClick={() => prefetchSelectedRow(key)}
                    bgcolor={selectedRow === key ? '#242c42' : '#222222'}
                    className={classes.tableComponent}>
                    <td contentEditable="true" className={classes.spaced}
                        onBlur={(e) => handleCellChange(e, key, 'extension')}>{key}</td>
                    <td contentEditable="true" className={classes.spaced}
                        onBlur={(e) => handleCellChange(e, key, 'description')}>{item['list_data'][key]['description']}</td>
                    <td><BackspaceOutlinedIcon color={"warning"} onClick={() => handleDeleteRow(key)} /></td>
                </tr>
                ))}
            </tbody>
        </table>
    )
}