import {getAccessToken} from "./Auth";
import axios from "axios";
import Notification from "../components/Notification";
import {Tooltip} from "@mui/material";


export function formatDate(epoch) {
    const options = { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'};
    const seconds = Math.trunc(epoch) * 1000
    return new Date(seconds).toLocaleString('en-US', options)
}


export function formatText(msg, n_chars) {
    if (msg.charAt(0) === '"' && msg.charAt(msg.length -1) === '"') {
        msg = msg.substring(1, msg.length -1)
    }
    if (msg.length > n_chars) {
        let title = msg.substring(0, n_chars)
        return <Tooltip title={<h3>{msg}</h3>} arrow>{title}</Tooltip>
    }
    else {
        return <div>{msg}</div>
    }
}


export function formatFileList(files) {
    const n_files = files.length
    if (n_files === 0) {
        return <div></div>
    }
    else if (n_files === 1) {
        return <div>{files[0]}</div>
    }
    else if (n_files === 2) {
        return <div>{files[0]}<br/>{files[1]}</div>
    }
    else {
        const tip = files.join("\n")
        return <Tooltip title={<div style={{whiteSpace: 'pre-line'}}>{tip}</div>}>
            <div>{files[0]} + {n_files - 1} more</div>
        </Tooltip>
    }
}


export function makeAlphanumeric(characters) {
        var cleaned = characters.replace(/[^a-zA-Z0-9_-]+/g,"");
        return cleaned.toLowerCase()
    }


export function epochSeconds() {
    var d = new Date();
    return Math.round(d.getTime() / 1000);
}


export function getTimeDeltaHuman(time) {
    let now = Date.now() / 1000
    const deltaTimeSeconds = now - time
    if (deltaTimeSeconds < 30) {
        return 'just now'
    }
    else if (deltaTimeSeconds < 180) {
        return 'about a minute ago'
    }
    else if (deltaTimeSeconds < 3000) {
        const mins = Math.round(deltaTimeSeconds / 60)
        return mins + ' minutes ago'
    }
    else if (deltaTimeSeconds < 80000) {
        const hrs = Math.round(deltaTimeSeconds / 60 / 60)
        return hrs + ' hours ago'
    }
    else {
        const days = Math.round(deltaTimeSeconds / 60 / 60 / 24)
        return days + ' days ago'
    }
}


export function isHidden(field, headers) {
        for (let i = 0; i < headers.length; i++) {
            if (headers[i]['field'] === field) {
                if (headers[i]['hidden'] === true) {
                    return true
                }
            }
        }
        return false
    }


export function getItemField(uuid, field, items) {
        // myArray.find(x => x.id === '45').foo;
        //console.log(JSON.stringify(items))
        console.log('uuid: ' + uuid)
        let item = items.find(x => x.pk === uuid)
        // console.log(JSON.stringify(item))
        if (item) {
            if (item[field]) {
                return item[field]
            }
            else {
                return ''
            }
        }
        else return ''
    }


export const getDetail = async (base_url, obj_type, uuid, setSelection, openEdit) => {
        let url = base_url + "/" + uuid
        const headers = { 'Content-Type': 'application/json','Authorization': await getAccessToken() }
        console.log('get: ' + url + ' ' + JSON.stringify(headers))
        await axios
            .get(url, { headers: headers })
            .then(res => {
                const item = res.data[obj_type];
                console.log(JSON.stringify(res))
                setSelection(item)
                console.log(obj_type + ' getDetail: ' + JSON.stringify(item))
                openEdit(true)
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + err.response)
                } else if (err.request) {
                  console.log('no response from server: ' + err.request)
                } else {
                  console.log(err)
                }
                Notification(`Error while fetching ${url}. ${err}`, 'error');
            })
    }
