import * as React from 'react';
import { useEffect, useState } from "react";
import axios from "axios";
import Constants from "../utils/Constants";
import { getAccessToken } from "../utils/Auth";
import Notification from "./Notification";
import { makeStyles, MenuItem, Select, Typography } from "@material-ui/core";
import { Paper, Stack, IconButton } from '@mui/material';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import LineChartComponent from './LineChartComponent';
import StackedAreaChartComponent from './StackedAreaChartComponent';
import HeatMapComponent from './HeatMapComponent';

let http_str = "https://";
let base_url = http_str.concat(Constants.apiHost, '.', Constants.domainName, '/api/v1/');

const useStyles = makeStyles(theme => ({
    card: {
        backgroundColor: '#222222',
        color: '#EEEEEE',
    },
    pageBar: {
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: '#222222',
        color: '#AAAAAA',
    },
    whiteColor: {
        color: "#EEE",
    },
    spaced: {
        margin: '10px',
    }
}));

export default function PerformanceCard() {
    const classes = useStyles();
    const [rawScans, setRawScans] = useState([]);
    const [scans, setScans] = useState([]);
    const [rawAlerts, setRawAlerts] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const now = new Date();
    const [time, setTime] = useState(now);
    const [timeframe, setTimeframe] = useState('hourly');
    const [xLabels, setXLabels] = useState([]);
    const [chartType, setChartType] = useState('line');

    useEffect(() => {
        pullData();
        const intervalId = setInterval(() => setTime(new Date()), 20000);
        return () => clearInterval(intervalId);
    }, [timeframe]);

    const getScans = async () => {
        const epochMillis = calculateEpochMillis(timeframe);
        const url = `${base_url}stats?after=${epochMillis}&scope=${timeframe}`;
        const http_headers = { "Content-Type": "application/json", "Authorization": await getAccessToken() };
        
        try {
            const res = await axios.get(url, { headers: http_headers });
            setRawScans(res.data.stats || []);
        } catch (err) {
            console.log('Error fetching scans:', err);
            Notification(`Error while fetching ${url}. ${err}`, 'error');
        }
    };

    const getAlerts = async () => {
        const epochMillis = calculateEpochMillis(timeframe);
        const url = `${base_url}alerts?after=${epochMillis}&scope=${timeframe}`;
        const http_headers = { "Content-Type": "application/json", "Authorization": await getAccessToken() };
        
        try {
            const res = await axios.get(url, { headers: http_headers });
            setRawAlerts(res.data.alerts || []);
        } catch (err) {
            console.log('Error fetching alerts:', err);
            Notification(`Error while fetching ${url}. ${err}`, 'error');
        }
    };

    const calculateEpochMillis = (timeframe) => {
        const d = new Date();
        let start_epoch_millis = d.getTime() * 100;

        if (timeframe === 'hourly') {
            start_epoch_millis -= 24 * 60 * 60 * 100 * 1000; // 24 hours
        } else if (timeframe === 'daily') {
            start_epoch_millis -= 7 * 24 * 60 * 60 * 100 * 1000; // 7 days
        } else if (timeframe === 'monthly') {
            start_epoch_millis -= 12 * 30 * 24 * 60 * 60 * 100 * 1000; // 12 months
        }

        return Math.max(160000000000000, Math.min(start_epoch_millis, 200000000000000));
    };

    const pullData = () => {
        getScans();
        getAlerts();
        generateTimeLabels(); // Generate labels based on the timeframe
    };

    const generateTimeLabels = () => {
        const labels = [];
        const currentTime = new Date();

        if (timeframe === 'hourly') {
            // Show last 24 hours, one-hour increments
            for (let i = 0; i < 25; i++) {
                const timeLabel = new Date(currentTime.getTime() - i * 60 * 60 * 1000);
                labels.unshift(timeLabel.toLocaleTimeString("en-US", { month: "2-digit", day: "2-digit", hour: "2-digit", hour12: true }));
            }
        } else if (timeframe === 'daily') {
            // Show the last 7 days, one-day increments
            for (let i = 0; i < 7; i++) {
                const timeLabel = new Date(currentTime.getTime() - i * 24 * 60 * 60 * 1000);
                labels.unshift(timeLabel.toLocaleDateString("en-US", { month: "short", day: "2-digit" }));
            }
        } else if (timeframe === 'monthly') {
            // Show the last 12 months, one-month increments
            for (let i = 0; i < 12; i++) {
                const timeLabel = new Date(currentTime.getTime() - i * 30 * 24 * 60 * 60 * 1000);
                labels.unshift(timeLabel.toLocaleDateString("en-US", { month: "short" }));
            }
        }
        setXLabels(labels);
    };

    useEffect(() => {
        recalcData();
    }, [rawScans, rawAlerts]);

    const recalcData = () => {
        const newScans = rawScans.map(scan => scan.events);
        const newAlerts = rawAlerts.map(alert => alert.events);

        setScans(newScans);
        setAlerts(newAlerts);
    };

    const renderChart = () => {
        const data = xLabels.map((label, index) => ({
            time: label,
            filesScanned: scans[index] || 0,
            alerts: alerts[index] || 0
        }));

        switch (chartType) {
            case 'line':
                return <LineChartComponent data={data} />;
            case 'stacked':
                return <StackedAreaChartComponent data={data} />;
            case 'heatmap':
                return <HeatMapComponent data={data} />;
            default:
                return <LineChartComponent data={data} />;
        }
    };

    return (
        <Paper className={classes.card} variant="outlined" style={{ padding: '15px' }}>
            {renderChart()}
            <Stack className={classes.pageBar} direction="row" alignItems="center">
                <IconButton onClick={pullData}><RefreshOutlinedIcon className={classes.spaced} /></IconButton>

                {/* Left-hand side: 24 hours ago */}
                <Typography className={classes.spaced}>
                    {new Date(time.getTime() - 24 * 60 * 60 * 1000).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: true })}
                </Typography>

                {/* Right-hand side: Current time */}
                <Typography className={classes.spaced}>
                    {time.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: true })}
                </Typography>

                <Select
                    classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                    id="timeframe-select"
                    value={timeframe}
                    onChange={(e) => setTimeframe(e.target.value)}
                >
                    <MenuItem value={'hourly'}>hourly</MenuItem>
                    <MenuItem value={'daily'}>daily</MenuItem>
                    <MenuItem value={'monthly'}>monthly</MenuItem>
                </Select>
                <Select
                    classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                    id="charttype-select"
                    value={chartType}
                    onChange={(e) => setChartType(e.target.value)}
                >
                    <MenuItem value={'line'}>Line Chart</MenuItem>
                    <MenuItem value={'stacked'}>Stacked Area Chart</MenuItem>
                    <MenuItem value={'heatmap'}>Heat Map</MenuItem>
                </Select>
            </Stack>
        </Paper>
    );
}
