import * as React from 'react';
import { Box, makeStyles, Typography } from "@material-ui/core";
import { Drawer, IconButton } from "@mui/material";
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';


const useStyles = makeStyles(theme => ({
    helpDrawer: {
        padding: '10px',
        margin: '10px',
    },
    paper: {
        width: '25%',
        //backgroundColor: '#181818',
        backgroundColor: '#111111',
        color: '#CCCCCC',
        zIndex: '100',
    },
    helpDrawerTitle: {
        backgroundColor: 'blue',
        color: '#CCCCCC',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        verticalAlign: "middle",
    },
    helpDrawerContent:{
        color: '#AAAAAA',
    }
}))


export default function HelpDrawer({open, onClose, page}) {
    const classes = useStyles()

    let title = 'Help'
    let content = '<h4>No Help for this page</h4>'

    if (page in helpText) {
        title = helpText[page]['title']
        content = helpText[page]['content']
    }

    return (
        <Drawer
            open={open}
            onClose={onClose}
            anchor='right'
            variant='persistent'
            className={classes.helpDrawer}
            classes={{paper: classes.paper}}
            >
            <Box className={classes.helpDrawer}>
                <br /><br /><br />
                <Box className={classes.HelpDrawerTitle}>
                    <IconButton onClick={onClose} size={'large'} color={'primary'}>
                        <DisabledByDefaultOutlinedIcon />
                    </IconButton>
                    <Typography display="inline" variant={'h5'}>{title}</Typography>
                </Box>
                <div className={classes.helpDrawerContent} dangerouslySetInnerHTML={{__html: content}}></div>
            </Box>
        </Drawer>
    )
}

const helpText = {
    '/': {
        'title': 'Dashboard',
        'content': '<p>The Dashboard includes all alerts in the top half.  You can click on the Acked field and ' +
            'acknowledge the alert, as well as make notes of your investigation.</p>' +
            '<p>The bottom half show statistics for how many files have been scanned over time, along with how many ' +
            'alerts have been found.</p>'
    },
    '/analyzers': {
        'title': 'Analyzers',
        'content': '<p>Analyzers are what actually does the work of monitoring file changes and to detect if the ' +
            'changes are due to ransomware.</p>' +
            '<p>Analyzers are deployed into your tenant in AWS, via CloudFormation, so that your data stays inside '+
            'your environent, and the analyzer remains performant.</p>' +
            'Steps:' +
            '<ol><li>Click the Add Icon in the top right of the table</li>' +
            '<li>Name your analyzer</li><li>Choose a Region</li><li>Choose a Site</li>' +
            '<li>Expand the Networking Section and enter your VPC ID and up to 3 Subnet IDs.  You can click the cloud ' +
            'icon to open your AWS console.  But you must copy/paste the IDs into the RansomStop portal</li>' +
            '<li>At this point you need to save your analyzer before you go to the deployment section.</li>' +
            '<li>Next Expand the Deployment section.  From there you can click on the cloud icon and it will ' +
            'redirect you to the AWS console to run the CloudFormation template.  Once comleted, you should ' +
            'copy the output from the cloudFormation stack and paste it back into the Analyzer dialog window.</li></ol>'
    },
    '/lists': {
        'title': 'Lists',
        'content': '<p>The default lists are read-only, but you can view the file extensions they match.</p>' +
            '<p>You can also create custom lists of file extensions that can then be applied in your policies ' +
            'as a custom block or safe list to control which files are blocked automatically or passed through.</p>'

    },
    '/logs': {
        'title': 'Logs',
        'content': '<p>Choose the analyzer you want to view logs for.</p>'
    },
    '/policies': {
        'title': 'Policies',
        'content': '<p>You can create multiple policies which can be assigned to different analyzers.</p>' +
            '<p>Policies give you the ability to control the responses (suspend users, block IPs, etc), as well as ' +
            'adding custom allow and block lists to customize the enforcement on a global or per analyzer basis</p>'
    },
    '/sites': {
        'title': 'Sites',
        'content': '<p>A Site represents a specific environment.  This could be an AWS or other cloud account, ' +
            'or even an on-prem location.  For each cloud account you want to deploy into, create a seperate site ' +
            'for each one</p>' +
            '<p>Sites that have been deployed will show a green pencil (edit) icon and sites that have not been ' +
            'deployed will show a red pencil icon</p>' +
            '<ul><li>Click Add or Edit a Site and expand the Deployment section of the dialog box.</li>' +
            '<li>Deloy the CloudFormation template by view (download) and executing it from the command line, ' +
            'or click on the Deploy Templatre icon to deploy directly in the AWS console.</li>' +
            '<li>When the CloudFormation template has been installed successfully, click the Policy Deployed ' +
            'switch to indicate it has been completed successfully</li>' +
            '<li>Ensure to click Save to save your changes</li>'
    },
}
