import React, { useState } from 'react';
import Constants from '../utils/Constants';
import Footer from './Footer';
import { getEmail } from '../utils/Auth';
import HelpDrawer from "./HelpDrawer";
import { makeStyles } from '@material-ui/core';
import { Box, Drawer } from '@material-ui/core';
import { Typography } from "@material-ui/core";
import { AppBar, Toolbar, Divider } from "@material-ui/core";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import PolicyRoundedIcon from '@mui/icons-material/PolicyRounded';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import FlipCameraAndroidRoundedIcon from '@mui/icons-material/FlipCameraAndroidRounded';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded'; // Add icon for Logs

const version = Constants.version;
const drawerWidth = 180;

const logout_url = 'https://' + Constants.authDomain + '/logout?client_id=' +
    Constants.userPoolClientId + '&logout_uri=https://' + Constants.customerName +
    '.' + Constants.domainName + '/logout'

const navLinkStyles = ({ isActive }) => {
    return {
        fontWeight: isActive ? 'bold' : 'normal',
        color: isActive ? '#ffffff' : '#bbbbbb',
        textDecoration: 'none',
    };
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    outer_div: {
        minHeight: '100vh',
    },
    drawer: {
        width: drawerWidth,
    },
    paper: {
        width: drawerWidth,
        backgroundColor: '#111111',
    },
    appbar: {
        width: '100%',
        elevation: '5',
        zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
        height: '75px',
    },
    logoDiv: {
        display: 'flex',
        width: '500px',
    },
    profileBar: {
        display: 'flex',
        marginLeft: 'auto',
        alignItems: 'center',
        padding: '10px',
        zIndex: theme.zIndex.drawer + 1,
    },
    navLabel: {
        fontWeight: 'normal',
        textDecoration: 'none',
        fontSize: '1.4rem',
        alignItems: 'center',
        justifyContent: 'center',
        lineHeight: '20px',
        display: 'flex',
        padding: '10px',
    },
    navDiv: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '10px',
        '&:hover': {
            background: "#222",
        },
    },
    divider: {
        background: '#bbbbbb',
    },
    versionLabel: {
        padding: '10px',
        top: '20px',
        color: '#5e5e5e',
    },
    spaced: {
        margin: '10px',
    },
}));

export default function Layout({ children }) {
    const classes = useStyles();
    const location = useLocation();
    const [helpDrawer, setHelpDrawer] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openProfile = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleToggleHelp = () => {
        setHelpDrawer(!helpDrawer);
    };

    const handleCloseHelp = () => {
        setHelpDrawer(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        localStorage.clear()
        window.location.replace(logout_url)
    };

    return (
        <div id='root' className={classes.root}>
            <AppBar position="fixed" className={classes.appbar}>
                <Toolbar className={classes.toolbar}>
                    <Box className={classes.logoDiv}>
                        <img src="/red-logo_white-text.png" alt="logo" height="45px" />
                    </Box>
                    <Box className={classes.profileBar}>
                        <IconButton onClick={handleToggleHelp} size={"large"}><ContactSupportOutlinedIcon /></IconButton>
                        <Typography className={classes.spaced} onClick={handleClick}>
                            {getEmail() ? getEmail() : 'not logged in'}
                        </Typography>
                        <Menu
                            id="profile-menu"
                            aria-labelledby="profile-button"
                            anchorEl={anchorEl}
                            open={openProfile}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <MenuItem onClick={logout}>Logout</MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                classes={{ paper: classes.paper }}
                variant="permanent"
                anchor="left"
            >
                <div id='toolbar' className={classes.toolbar} />
                <nav id='nav' className={classes.paper}>
                    <div className={classes.toolbar}></div>
                    <Box className={classes.navDiv}>
                        <div><DashboardRoundedIcon color={'primary'} /></div>
                        <NavLink style={navLinkStyles} className={classes.navLabel} to={'/'}>Dashboard</NavLink>
                    </Box>
                    <Box className={classes.navDiv}>
                        <div><PolicyRoundedIcon color={'primary'} /></div>
                        <NavLink style={navLinkStyles} className={classes.navLabel} to={'/policies'}>Policies</NavLink>
                    </Box>
                    <Box className={classes.navDiv}>
                        <div><ListRoundedIcon color={'primary'} /></div>
                        <NavLink style={navLinkStyles} className={classes.navLabel} to={'/lists'}>Lists</NavLink>
                    </Box>
                    <Box className={classes.navDiv}>
                        <div><FlipCameraAndroidRoundedIcon color={'primary'} /></div>
                        <NavLink style={navLinkStyles} className={classes.navLabel} to={'/analyzers'}>Analyzers</NavLink>
                    </Box>
                    <Box className={classes.navDiv}>
                        <div><AssignmentRoundedIcon color={'primary'} /></div>
                        <NavLink style={navLinkStyles} className={classes.navLabel} to={'/logs'}>Logs</NavLink>
                    </Box>
                    <Box className={classes.navDiv}>
                        <div><CorporateFareRoundedIcon color={'primary'} /></div>
                        <NavLink style={navLinkStyles} className={classes.navLabel} to={'/sites'}>Sites</NavLink>
                    </Box>
                    <Box className={classes.navDiv}>
                        <div><SettingsRoundedIcon color={'primary'} /></div>
                        <NavLink style={navLinkStyles} className={classes.navLabel} to={'/settings'}>Settings</NavLink>
                    </Box>
                    <div className={classes.toolbar}></div>
                    <Divider classes={{ root: classes.divider }} />
                    <Box className={classes.versionLabel}>
                        <Typography variant='caption' className={classes.versionLabel}>
                            ui version:
                        </Typography>
                        <Typography variant='caption' className={classes.versionLabel}>
                            {version}
                        </Typography>
                    </Box>
                </nav>
            </Drawer>
            <div className={classes.page}>
                <div className={classes.toolbar}></div>
                {children}
                <Footer />
            </div>

            {/* HELP */}
            <HelpDrawer
                open={helpDrawer}
                onClose={handleCloseHelp}
                page={location.pathname}
            />
        </div>
    );
}
