import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import Constants from "../utils/Constants";
import { getAccessToken } from "../utils/Auth";
import Notification from "./Notification";
import { formatDate, isHidden } from "../utils/Functions";
import ColumnsDialog from "./ColumnsDialog";
import { Paper, Stack, IconButton } from "@mui/material";
import { Box, Select, MenuItem, Typography, makeStyles } from '@material-ui/core';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';

let http_str = "https://";
let base_url = http_str.concat(Constants.apiHost, '.', Constants.domainName, '/api/v1/logs');
let analyzers_url = http_str.concat(Constants.apiHost, '.', Constants.domainName, '/api/v1/analyzers');

const useStyles = makeStyles(theme => ({
    outer_div: {
        minHeight: '100vh',
    },
    card: {
        backgroundColor: '#424242',
        color: '#EEEEEE',
    },
    itemBar: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#222222',
    },
    pageBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        backgroundColor: '#222222',
        color: '#AAAAAA',
    },
    tableComponent: {
        borderStyle: 'solid',
        borderColor: '#000',
        borderRadius: '10px',
        borderSpacing: '0px',
        borderRightWidth: '0px',
        borderTopWidth: '1px',
        borderBottomWidth: '0px',
        borderLeftWidth: '0px',
    },
    tableCell: {
        paddingLeft: '12px',
    },
    clickableTableCell: {
        paddingLeft: '12px',
        cursor: 'pointer',
    },
    dialog: {
        backgroundColor: '#333333',
        color: '#CCCCCC',
        margin: '10',
    },
    dialogTitleBar: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
    },
    dialogConfirm: {
        backgroundColor: '#149616',
        color: '#CCCCCC',
    },
    dialogNeutral: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
    },
    dialogWarning: {
        backgroundColor: '#d42842',
        color: '#CCCCCC',
    },
    primaryColor: {
        color: '#000',
        backgroundColor: '#111'
    },
    whiteColor: {
        color: "#EEE",
    },
    hide: {
        display: 'none',
    },
    spaced: {
        margin: '10px',
    }
}));

let headers = [
    { field: 'node_name', headerName: 'Analyzer', width: 150, hidden: false },
    { field: 'msg', headerName: 'Message', width: 600, hidden: false },
    { field: 'level', headerName: 'Level', width: 75, hidden: false },
    { field: 'ip', headerName: 'Source IP', width: 100, hidden: false },
    { field: 'region', headerName: 'Region', width: 110, hidden: false },
    { field: 'created', headerName: 'Created', width: 200, hidden: false },
];

export default function Logs() {
    const classes = useStyles();
    const [items, setItems] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [columnsMode, setColumnsMode] = useState(false);
    const [limit, setLimit] = useState(10);
    const [pages, setPages] = useState([0]);
    const [pageIndex, setPageIndex] = useState(0);
    const [analyzers, setAnalyzers] = useState([]);
    const [selectedAnalyzer, setSelectedAnalyzer] = useState('');
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const getItems = useCallback(async () => {
        console.log('analyzer = ' + selectedAnalyzer)
        if (selectedAnalyzer !== '') {
            let url = base_url;
            if (pageIndex !== 0) {
                url = url + '?limit=' + limit + '&node=' + selectedAnalyzer + '&nextPageKey=' + pages[pageIndex]
            } else {
                url = url + '?limit=' + limit + '&node=' + selectedAnalyzer
            }
            const http_headers = {"Content-Type": "application/json", "Authorization": await getAccessToken()};
            console.log('Fetching logs from URL: ' + url);
            await axios
                .get(url, {headers: http_headers})
                .then(res => {
                    console.log('Logs API response:', JSON.stringify(res.data));
                    if (pageIndex === pages.length - 1) {
                        let npk = res.data['nextPageKey'];
                        if (npk) {
                            let tmp_arr = pages;
                            tmp_arr.push(npk);
                            setPages(tmp_arr);
                        }
                    }
                    const items = res.data['logs'];
                    setItems(items);
                })
                .catch(err => {
                    if (err.response) {
                        console.log('Bad response from server: ' + err.response);
                    } else if (err.request) {
                        console.log('No response from server: ' + err.request);
                    } else {
                        console.log(err);
                    }
                    Notification(`Error while fetching ${url}. ${err}`, 'error');
                });
        }
    }, [pageIndex, limit, pages, selectedAnalyzer]);

    const getAnalyzers = async () => {
        const http_headers = { "Content-Type": "application/json", "Authorization": await getAccessToken() };
        try {
            const response = await axios.get(analyzers_url, { headers: http_headers });
            console.log('Analyzers API response:', response.data);
            const analyzers = response.data['analyzers'];
            setAnalyzers(analyzers);
        } catch (err) {
            if (err.response) {
                console.log('bad response from server: ' + err.response);
            } else if (err.request) {
                console.log('no response from server: ' + err.request);
            } else {
                console.log(err);
            }
            Notification(`Error while fetching ${analyzers_url}. ${err}`, 'error');
        }
    };

    const handleAnalyzerChange = (event) => {
        setSelectedAnalyzer(event.target.value);
        setPageIndex(0);
        setPages([0]);
        getItems(event.target.value);
    };

    const handleOpenColumns = () => {
        setColumnsMode(true);
    };

    const handleCloseColumns = () => {
        setColumnsMode(false);
    };

    const toggleColumn = (event) => {
        for (let i = 0; i < headers.length; i++) {
            if (headers[i]['field'] === event.target.id) {
                headers[i]['hidden'] = !headers[i]['hidden'];
            }
        }
        forceUpdate();
    };

    const handleLimit = (event) => {
        setLimit(event.target.value);
        setPages([0]);
        setPageIndex(0);
    };

    const handlePreviousPage = () => {
        if (pageIndex > 0) {
            setPageIndex(pageIndex - 1);
        }
    };

    const handleNextPage = () => {
        if (pageIndex < pages.length - 1) {
            setPageIndex(pageIndex + 1);
        }
    };

    useEffect(() => {
        if (selectedAnalyzer) {
            let prom = getItems();
        }
    }, [pageIndex, limit, selectedAnalyzer]);

    useEffect(() => {
        let prom = getAnalyzers();
    }, []);

    return (
        <div className={classes.outer_div} style={{ padding: '20px' }}>
            <Paper className={classes.card} variant="outlined" style={{ padding: '15px' }}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="h4">Logs</Typography>
                    <Box
                        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
                    >
                        <Select
                            classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                            value={selectedAnalyzer}
                            onChange={handleAnalyzerChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value="">
                                <em>select analyzer</em>
                            </MenuItem>
                            {analyzers.map(analyzer => (
                                <MenuItem key={analyzer.pk} value={analyzer.pk}>
                                    {analyzer.pk}
                                </MenuItem>
                            ))}
                        </Select>
                        <ViewWeekOutlinedIcon className={classes.spaced} color={"primary"} onClick={handleOpenColumns} />
                    </Box>
                </Stack>
                {/* TABLE */}
                <table className={classes.tableComponent} style={{width: 100 + "%"}} >
                    <thead>
                        <tr className={classes.tableComponent}>
                            {headers.map(header => (
                                <th key={header.field} width={header.width} className={header.hidden === true ? classes.hide : ''}>{header.headerName}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className={classes.tableComponent}>
                        {items.map(val =>
                            <tr key={val.pk} bgcolor={selectedRow === val.pk ? '#242c42' : '#222222'}
                                className={classes.tableComponent}>
                                <td className={isHidden('node_name', headers) ? classes.hide : classes.tableCell}>{val.hk}</td>
                                <td className={isHidden('msg', headers) ? classes.hide : classes.tableCell}>{val.msg}</td>
                                <td className={isHidden('level', headers) ? classes.hide : classes.tableCell}>{val.level}</td>
                                <td className={isHidden('ip', headers) ? classes.hide : classes.tableCell}>{val.local_ip}</td>
                                <td className={isHidden('region', headers) ? classes.hide : classes.tableCell}>{val.region}</td>
                                <td className={isHidden('created', headers) ? classes.hide : classes.tableCell}>{formatDate(val.created)}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <Paper className={classes.itemBar} elevation={1}>
                    <Stack className={classes.pageBar}
                           elevation={0}
                           direction="row"
                           alignItems="center"
                           sx={{ width: 1, height: "5vh" }}>
                        <IconButton onClick={() => getItems()}><RefreshOutlinedIcon className={classes.spaced} /></IconButton>
                        <Typography className={classes.spaced}>Rows per page:</Typography>
                        <Select
                            classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                            labelId="fetch-limit-select"
                            id="fetch-limit-select"
                            value={limit}
                            label="Limit"
                            onChange={handleLimit}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>
                        <IconButton disabled={pageIndex === 0} onClick={() => handlePreviousPage()}><ChevronLeftOutlinedIcon /></IconButton>
                        <Typography variant={'h6'}>{pageIndex + 1}</Typography>
                        <IconButton disabled={pageIndex >= pages.length - 1} onClick={() => handleNextPage()}><ChevronRightOutlinedIcon /></IconButton>
                    </Stack>
                </Paper>

                {/* DIALOGS */}
                {/* COLUMNS DIALOG */}
                <ColumnsDialog
                    variant={"outlined"}
                    open={columnsMode}
                    onClose={handleCloseColumns}
                    onChange={toggleColumn}
                    headers={headers}
                />
            </Paper>
        </div>
    );
}
