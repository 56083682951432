import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { login } from '../utils/Auth';
import { formatDate } from "../utils/Functions";
import { jwtDecode } from "jwt-decode";
import { makeStyles } from "@material-ui/core"
import { Paper } from "@mui/material";

// @TODO this can probably be folded into App.js or Layout.js since it's really just processing the callback

const useStyles = makeStyles(theme => ({
    outer_div: {
        minHeight: '100vh',
    },
    card: {
        //backgroundColor: '#424242',
        backgroundColor: '#181818',
        color: '#EEEEEE',
    },
}))


const Login = ({ setIsAuthenticated }) => {
    const classes= useStyles()
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate();

    const process_login = () => {
    login(setIsAuthenticated, searchParams.get('code'), searchParams.get('state'))
        const beforeLoginUrl = sessionStorage.getItem('beforeLogin');
        if (beforeLoginUrl === '/login' || beforeLoginUrl === '/logout') {
            navigate('/')
        }
        else {
            navigate(beforeLoginUrl ? beforeLoginUrl : '/');
        }
    }

    useEffect(() => {
        let access_token = localStorage['access_token']
        if (access_token === undefined || access_token === null) {
            process_login()
        }
        else {
            let decoded_access = jwtDecode(access_token)
            let access_exp = decoded_access['exp']
            var now = new Date()
            console.log('expiration: ' + formatDate(access_exp))
            console.log('exp secs: ' + access_exp)
            var now_seconds = Math.round(now.getTime() / 1000)
            console.log('now: ' + formatDate(now_seconds))
            console.log('now secs: ' + now_seconds)
            console.log('diff secs: ' + (access_exp - now_seconds))
            if (access_exp < now_seconds) {
                process_login()
            }
            else {
                console.log('acc_token: ' + access_token)
                console.log('access token already exists, skip login')
            }
        }
    }, [])


    return (
     <div className={classes.outer_div} style={{padding: '20px'}}>
            <Paper className={classes.card} variant="outlined" style={{padding: '15px'}}>
                <div className={"mainContainer"}>
                    <div className={"titleContainer"}>
                        <div>Debug</div>
                    </div>
                    <br/>
                    <div>verifier: {localStorage.getItem('verifier')}</div>
                    <br/>
                    <div>challenge: {localStorage.getItem('challenge')}</div>
                    <br/>
                    <div>code: {searchParams.get('code')}</div>
                    <br/>
                </div>
            </Paper>
     </div>
    )
}

export default Login