import Constants from "./Constants";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import qs from "qs";
import { formatDate } from "./Functions";


const sha256 = async (str) => {
	return await crypto.subtle.digest("SHA-256", new TextEncoder().encode(str));
};

const generateNonce = async () => {
	const hash = await sha256(crypto.getRandomValues(new Uint32Array(4)).toString());
	// https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
	const hashArray = Array.from(new Uint8Array(hash));
	return hashArray.map(b => b.toString(16).padStart(2, "0")).join("");
};

const base64URLEncode = (string) => {
	return btoa(String.fromCharCode.apply(null, new Uint8Array(string)))
		.replace(/\+/g, "-")
		.replace(/\//g, "_")
		.replace(/=+$/, "")
};

export function authenticated() {
    // @TODO should probably try and refresh access token like in getAccessToken()
    let access_token = localStorage.getItem('access_token')
    if (access_token !== 'undefined' && access_token !== null) {
        // console.log('access_token: ' + access_token)
        let decoded_access = jwtDecode(access_token)
        let access_exp = decoded_access['exp']
        var now = new Date()
        var now_seconds = Math.round(now.getTime() / 1000)
        if (access_exp > now_seconds - 2) {
            return true
        }
    }
    return false
}

export async function getAccessToken() {
    // Check if access token is valid, if not, check refresh token, otherwise log out
    let access_token = localStorage.getItem('access_token')
    if (access_token !== undefined && access_token !== null) {
        let decoded_access = jwtDecode(access_token)
        let access_exp = decoded_access['exp']
        var now = new Date()
        var now_seconds = Math.round(now.getTime() / 1000)
        if (access_exp > now_seconds - 2) {
            return access_token.toString()
        } else {
            console.log('access token expired')
            // refresh access token
            let refresh = await refresh_tokens()
            access_token = localStorage.getItem('access_token')
            return access_token.toString()
        }
    }
    else {
        let refresh = await refresh_tokens()
        localStorage.getItem('access_token')
        access_token = localStorage.getItem('access_token')
        return access_token.toString()
    }
}

export function getEmail() {
    let email = localStorage.getItem('email')
    if (email !== undefined && email !== null) {
        return email
    }
    else {
        return null
    }
}

export async function getChallengeCodes(setHref)  {
        console.log('start getting challenge codes')
        const origin = window.location.origin
        console.log('origin: ' + origin)
        const state = await generateNonce()
        const code_verifier = await generateNonce()
        const code_challenge = base64URLEncode(await sha256(code_verifier))
        localStorage.setItem(`verifier-${state}`, code_verifier)
        localStorage.setItem('challenge', code_challenge)
        const href = "https://" + Constants.authDomain + "/oauth2/authorize?code_challenge=" +
            code_challenge + "&response_type=code&redirect_uri=" + origin + "/login/&client_id=" +
            Constants.userPoolClientId + "&code_challenge_method=S256&state=" + state
        localStorage.setItem('href', href)
        setHref(href)
}

export async function login(setIsAuthenticated, code, state) {
        console.log('start login')
        const origin = window.location.origin
        console.log('origin: ' + origin)
        let http_headers = {'Content-Type':'application/x-www-form-urlencoded'}
        // console.log('headers: ' + JSON.stringify(http_headers))
        let params = {
            'redirect_uri': origin + "/login/",
            'client_id': Constants.userPoolClientId,
            'code': code,
            'grant_type': 'authorization_code',
            'code_verifier': localStorage.getItem(`verifier-${state}`)
        }
        await axios
            .post('https://' +  Constants.authDomain + '/oauth2/token',
                qs.stringify( params ),{ headers: http_headers })
            .then(res => {
                // let access_obj = jwtDecode(res['data']['access_token'])
                // console.log('access: ' + JSON.stringify(access_obj))
                let id_obj = jwtDecode(res['data']['id_token'])
                // console.log('id: ' + JSON.stringify(id_obj))
                localStorage.setItem('username', id_obj['sub'])
                localStorage.setItem('email', id_obj['email'])
                localStorage.setItem('access_token', res['data']['access_token'])
                localStorage.setItem('id_token', res['data']['id_token'])
                localStorage.setItem('refresh_token', res['data']['refresh_token'])
                setIsAuthenticated(true)
                localStorage.removeItem(`verifier-${state}`)
                localStorage.removeItem(`href`)
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + JSON.stringify(err.response))
                } else if (err.request) {
                  console.log('no response from server: ' + err.request)
                } else {
                  console.log(err)
                }
            })
    }

    export async function refresh_tokens() {
        let refresh_token = localStorage.getItem('refresh_token')
        let http_headers = {'Content-Type':'application/x-www-form-urlencoded'}
        let params = {
            'grant_type': 'refresh_token',
            'client_id': Constants.userPoolClientId,
            'refresh_token': refresh_token
        }
        await axios
            .post('https://' +  Constants.authDomain + '/oauth2/token',
                qs.stringify( params ),{ headers: http_headers })
            .then(res => {
                localStorage.setItem('access_token', res['data']['access_token'])
                localStorage.setItem('id_token', res['data']['id_token'])
                // setIsAuthenticated(true)
                //currentAuthenticatedUser()
                let id_obj = jwtDecode(res['data']['id_token'])
                let access_obj = jwtDecode(res['data']['access_token'])
                //console.log('id: ' + JSON.stringify(id_obj))
                console.log('sub: ' + jwtDecode(res['data']['id_token'])['sub'])
                console.log('email: ' + jwtDecode(res['data']['id_token'])['email'])
                //console.log('access: ' + JSON.stringify(access_obj))
                localStorage.setItem('username', id_obj['sub'])
                localStorage.setItem('email', id_obj['email'])
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + JSON.stringify(err.response))
                } else if (err.request) {
                  console.log('no response from server: ' + err.request)
                } else {
                  console.log(err)
                }
            })
    }

export function logout() {
    localStorage.removeItem('href')
    localStorage.removeItem('access_token')
    localStorage.removeItem('id_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('username')
    localStorage.removeItem('email')

    // @TODO reload() will have to be done where logout is called, not in Auth.js
    //setAnchorEl(null);
    window.location.reload();
}