import React from "react";
import {Box, makeStyles, Typography} from "@material-ui/core";
import { Stack } from "@mui/material"

const useStyles = makeStyles(theme => ({
    card: {
        backgroundColor: '#424242',
        color: '#EEEEEE',
    },
    footerBar: {
        position: 'relative',
        width: '90%',
        bottom: 10,
        //right: 200,
        left: 20,
        zIndex: 7,
    },
    versionLabel: {
        padding: '10px',
        top: '20px',
        color: '#5e5e5e',
        '& a:focus, a:hover, a:visited, a:link, a:active': {
            textDecoration: 'none',
            color: '#5e5e5e',
        }
    },
    spaced: {
        margin: '10px',
    },
}))

export default function Footer() {
    const classes = useStyles()

    return (
            <Stack direction={"row"} justifyContent={"space-between"} className={classes.footerBar}>
                <Box>
                    <Typography variant='caption' className={classes.versionLabel}>
                        <span>&copy; 2024 <a href="https://ransomstop.com" target="_blank" rel="noreferrer">RansomStop, Inc.</a>  All Rights Reserved.</span>
                    </Typography>
                </Box>
                <Box>
                    <Typography variant='caption' className={classes.versionLabel}>
                        <a href="https://www.youtube.com/@RansomStop" target="_blank" rel="noreferrer">YouTube</a> |&nbsp;
                        LinkedIn |&nbsp;
                        <a href="https://infosec.exchange/@RansomStop" target="_blank" rel="noreferrer">Mastodon</a>
                    </Typography>
                </Box>
            </Stack>
    )
}