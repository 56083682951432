import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Constants from "../utils/Constants";
import { getAccessToken } from "../utils/Auth";
import Notification from "./Notification"
import ColumnsDialog from './ColumnsDialog';
import ListDialog from './ListDialog';
import { formatDate, getDetail, isHidden, epochSeconds } from "../utils/Functions";
import { Paper } from '@mui/material'
import { Box, Select, MenuItem, Typography, makeStyles } from '@material-ui/core'
import { Stack, IconButton } from "@mui/material"
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import DeleteDialog from "./DeleteDialog";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";


let http_str = "https://"
let base_url = http_str.concat(Constants.apiHost, '.', Constants.domainName,'/api/v1/lists')


const useStyles = makeStyles(theme => ({
    outer_div: {
        minHeight: '100vh',
    },
    card: {
        backgroundColor: '#424242',
        color: '#EEEEEE',
    },
    itemBar: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#222222',
    },
    pageBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        backgroundColor: '#222222',
        color: '#AAAAAA',
    },
    tableComponent: {
        borderStyle: 'solid',
        borderColor: '#000',
        borderRadius: '10px',
        borderSpacing: '0px',
        borderRightWidth: '0px',
        borderTopWidth: '1px',
        borderBottomWidth: '0px',
        borderLeftWidth: '0px',
        // backgroundColor: '#222222' // setting this breaks row highlighting
    },
    tableCell: {
        paddingLeft: '12px',
    },
    clickableTableCell: {
        paddingLeft: '12px',
        cursor: 'pointer',
    },
    dialog: {
        backgroundColor: '#333333',
        color: '#CCCCCC',
        margin: '10',
    },
    dialogTitleBar: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
    },
    dialogConfirm: {
        backgroundColor: '#149616',
        color: '#CCCCCC',
    },
    dialogNeutral: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
    },
    dialogWarning: {
        backgroundColor: '#d42842',
        color: '#CCCCCC',
    },
    primaryColor: {
        color: '#000',
        backgroundColor: '#111'
    },
    whiteColor: {
        color: "#EEE",
    },
    hide: {
        display: 'none',
    },
    spaced: {
        margin: '10px',
    }
}))

let headers = [
  { field: 'pk', headerName: 'ID', width: 350, hidden: true },
  { field: 'list_name', headerName: 'Name', width: 0, hidden: false },
  { field: 'description', headerName: 'Description', width: 300, hidden: true },
  { field: 'list_type', headerName: 'Type', width: 150, hidden: false },
  { field: 'count', headerName: 'Items', width: 50, hidden: false },
  { field: 'ref_count', headerName: 'Used', width: 50, hidden: false },
  { field: 'created', headerName: 'Created', width: 200, hidden: false},
  { field: 'modified', headerName: 'Modified', width: 200, hidden: false},
  { field: 'edit', headerName: 'Edit', width: 50, hidden: false },
    { field: 'delete', headerName: 'Delete', width: 50, hidden: false },
  ]

export default function Lists() {
    const classes = useStyles()
    const [items, setItems] = useState([])
    const [selectedItem, setSelectedItem] = useState(null)
    const [selectedRow, setSelectedRow] = useState(null)
    const [columnsMode, setColumnsMode] = useState(false)
    const [addMode, setAddMode] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [deleteMode, setDeleteMode] = useState(false)
    const [limit, setLimit] = React.useState(10)
    const [pages, setPages] = useState([0])
    const [pageIndex, setPageIndex] = useState(0)
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const getItems = async () => {
        let url = base_url
        if (pageIndex !== 0) {
            console.log('fetch page: ' + pages[pageIndex])
            url = url + '?limit=' + limit + '&nextPageKey=' + pages[pageIndex]
        }
        else {
            console.log('fetch page: 0')
            url = url + '?limit=' + limit
        }
        const http_headers = { "Content-Type": "application/json","Authorization": await getAccessToken() }
        console.log('url: ' + url)
        console.log('headers: ' + JSON.stringify(http_headers))
        await axios
            .get(url, { headers: http_headers })
            .then(res => {
                if (pageIndex === pages.length - 1) {
                    let npk = res.data['nextPageKey']
                    if (npk) {
                        let tmp_arr = pages
                        tmp_arr.push(npk)
                        setPages(tmp_arr)
                    }
                }
                const items = res.data['lists'];
                console.log(items);
                setItems(items)
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + err.response)
                } else if (err.request) {
                  console.log('no response from server: ' + err.request)
                } else {
                  console.log(err)
                }
                Notification(`Error while fetching ${url}. ${err.response.data}`, 'error');
            })
    }


    const addItem = async (item) => {
        console.log('item to add: ' + JSON.stringify(item))
        let url = base_url
        const http_headers = { 'Content-Type': 'application/json','Authorization': await getAccessToken() }
        let params = {
            list_name: item.list_name,
            description: item.description,
            list_type: item.list_type,
            list_data: item.list_data,
        }
        await axios
            .post(url, { 'list': params },{ headers: http_headers })
            .then(res => {
                console.log(res.data);
                // Add the data submitted back to the local table so it is correct before server side refresh
                params.pk = res.data['pk']
                const epoch = epochSeconds()
                params.created = epoch
                params.modified = epoch
                setItems(items.concat([params]))
                Notification(res.data['msg'], 'info');
                forceUpdate()
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + err.response)
                } else if (err.request) {
                  console.log('no response from server: ' + err)
                } else {
                  console.log(err)
                }
                Notification(`Error updating list. ${err.response.data}`, 'error');
            })
    }

    const editItem = async (item) => {
        console.log('item to save: ' + JSON.stringify(item))
        let url = base_url + "/" + item.pk
        const http_headers = { 'Content-Type': 'application/json','Authorization': await getAccessToken() }
        let params = {
            pk: item.pk,
            list_name: item.list_name,
            description: item.description,
            list_type: item.list_type,
            list_data: item.list_data,
            ref_count: item.ref_count,
        }
        await axios
            .put(url, { 'list': params },{ headers: http_headers })
            .then(res => {
                console.log(res.data);
                // Add the data submitted back to the local table
                // setItems(items.concat([params]))
                Notification(res.data['msg'], 'info');
                forceUpdate()
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + JSON.stringify(err.response.data))
                } else if (err.request) {
                  console.log('no response from server: ' + err)
                } else {
                  console.log(err)
                }
                Notification(`Error updating list. ${err.response.data}`, 'error');
            })
    }

    const deleteItem = async (uuid) => {
        let url = base_url + "/" + uuid
        const headers = { 'Content-Type': 'application/json','Authorization': await getAccessToken() }
        await axios
            .delete(url, { headers: headers })
            .then(res => {
                console.log(res.data);
                let items_filtered = items.map((x) => x)
                items_filtered = items_filtered.filter(i => i.pk !== uuid)
                setItems(items_filtered)
                Notification(res.data, 'info');
            })
            .catch(err => {
                let error_response = 'unknown error'
                if (err.response) {
                    error_response = err.response['data']
                  console.log('bad response from server: ' + JSON.stringify(err.response))
                } else if (err.request) {
                    error_response = err
                  console.log('no response from server: ' + err)
                } else {
                    error_response = err
                  console.log(err)
                }
                Notification(`Error: deleting ${uuid}. ${error_response}`, 'error');
            })
    }

    // PrefetchSelectedRow will fetch list details every row you click
    // It should make loading the edit screen faster, but it might add a lot more API calls
    const prefetchSelectedRow = (uuid) => {
        if (selectedRow === uuid) {
            // deselect a row if it's already selected
            setSelectedRow(null)
        }
        else {
            setSelectedRow(uuid)
            setSelectedItem(getItemByPk(uuid))
            //getDetail(uuid)
        }
    }

    function getItemByPk(pk) {
        return items.find(i => i.pk === pk)
    }

    const handleOpenColumns = () => {
        setColumnsMode(true);
    };

    const handleCloseColumns = () => {
        setColumnsMode(false);
    };

    const handleOpenAdd = (e) => {
        e.stopPropagation()
        console.log("add new list")
        setAddMode(true)
    };

    const handleAdd = (item) => {
        let editData = addItem(item)
        setAddMode(false)
    }

    const handleCloseAdd = () => {
        setAddMode(false);
    };

    const handleOpenEdit = (e, uuid) => {
        setSelectedRow(uuid)
        console.log("edit " + selectedRow)
        getDetail(base_url, 'list', uuid, setSelectedItem, setEditMode)
    };

    const handleEdit = () => {
        let editData = editItem(selectedItem)
        setEditMode(false)
    }

    const handleCloseEdit = () => {
        setEditMode(false);
    };

    const handleOpenDelete = (e, uuid) => {
        // stopPropagation seems to block selecting and editing a row in 1 click, so remove it if it doesn't cause any other issues
        //e.stopPropagation()
        setSelectedRow(uuid)
        setDeleteMode(true);
        console.log("delete " + selectedRow)
    };

    const handleDelete = (uuid) => {
        deleteItem(uuid)
        setDeleteMode(false);
    };

    const handleCloseDelete = () => {
        setDeleteMode(false);
    };

    const handleLimit = (event) => {
        setLimit(event.target.value);
        setPages([0])
        setPageIndex(0)
    };

    const handlePreviousPage = () => {
        if (pageIndex > 0) {
            setPageIndex(pageIndex - 1)
        }
    }

    const handleNextPage = () => {
        if (pageIndex < pages.length - 1) {
            setPageIndex(pageIndex + 1)
        }
    }

    const toggleColumn = (event) => {
        for (let i = 0; i < headers.length; i++) {
            if (headers[i]['field'] === event.target.id) {
                headers[i]['hidden'] = !headers[i]['hidden']
            }
        }
        //  ugly but since the checkboxes aren't tied to state, they are tied to column definitions, they don't update
        forceUpdate()
    };

    useEffect(() => {
        getItems()
    }, [pageIndex, limit] )

    return (
        <div className={classes.outer_div} style={{padding: '20px'}}>
            <Paper className={classes.card} variant="outlined" style={{padding: '15px'}}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="h4" >Lists</Typography>
                    <Box>
                        <ViewWeekOutlinedIcon className={classes.spaced} color={"primary"}
                                              onClick={() => handleOpenColumns()}/>
                        <AddOutlinedIcon className={classes.spaced} color={"primary"}
                                         onClick={(e) => handleOpenAdd(e)}/>
                    </Box>
                </Stack>
                {/* TABLE */}
                <table className={classes.tableComponent} style={{width: 100 + "%"}} >
                    <thead>
                        <tr className={classes.tableComponent} >
                            {headers.map(header => (
                                <th width={header.width} className={ header.hidden === true ? classes.hide: '' }>{header.headerName}</th>
                                ))}
                        </tr>
                    </thead>
                    <tbody  className={classes.tableComponent} >
                        {items.map(val =>
                            <tr key={val.pk} onClick={() => prefetchSelectedRow(val.pk)}
                                bgcolor={selectedRow === val.pk ? '#242c42' : '#222222'}
                                className={classes.tableComponent}>
                                <td className={isHidden('pk', headers) ? classes.hide : classes.tableCell}>{val.pk}</td>
                                <td className={isHidden('list_name', headers) ? classes.hide : classes.tableCell}>{val.list_name}</td>
                                <td className={isHidden('description', headers) ? classes.hide : classes.tableCell}>{val.description}</td>
                                <td className={isHidden('list_type', headers) ? classes.hide : classes.tableCell}>{val.list_type}</td>
                                <td className={isHidden('item_count', headers) ? classes.hide : classes.tableCell}>{val.item_count}</td>
                                <td className={isHidden('ref_count', headers) ? classes.hide : classes.tableCell}>{val.ref_count}</td>
                                <td className={isHidden('created', headers) ? classes.hide : classes.tableCell}>{formatDate(val.created)}</td>
                                <td className={isHidden('modified', headers) ? classes.hide : classes.tableCell}>{formatDate(val.modified)}</td>
                                <td className={isHidden('edit', headers) ? classes.hide : classes.clickableTableCell}>
                                    <EditOutlinedIcon color={"primary"} onClick={(e) => handleOpenEdit(e, val.pk)}/>
                                </td>
                                <td className={isHidden('delete', headers) ? classes.hide : classes.clickableTableCell}>
                                    <BackspaceOutlinedIcon color={"error"}
                                                           onClick={(e) => handleOpenDelete(e, val.pk)}/></td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <Paper className={classes.itemBar} elevation={1}>
                    <Stack className={classes.pageBar}
                        elevation={0}
                        direction="row"
                        alignItems="center"
                        sx={{ width: 1, height: "5vh" }} >
                            <IconButton onClick={() => getItems()}><RefreshOutlinedIcon className={classes.spaced} /></IconButton>
                            <Typography className={classes.spaced}>Rows per page:</Typography>
                            <Select
                                classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                                labelId="fetch-limit-select"
                                id="fetch-limit-select"
                                value={limit}
                                label="Limit"
                                onChange={handleLimit}
                            >
                              <MenuItem value={10}>10</MenuItem>
                              <MenuItem value={25}>25</MenuItem>
                              <MenuItem value={50}>50</MenuItem>
                            </Select>
                            <IconButton disabled={pageIndex === 0} onClick={() => handlePreviousPage()}><ChevronLeftOutlinedIcon /></IconButton>
                            <Typography variant={'h6'}>{pageIndex + 1}</Typography>
                            <IconButton disabled={pageIndex >= pages.length - 1} onClick={() => handleNextPage()}><ChevronRightOutlinedIcon /></IconButton>
                    </Stack>
                </Paper>

                {/* DIALOGS */}
                {/* COLUMNS DIALOG */}
                <ColumnsDialog
                    variant={"outlined"}
                    open={columnsMode}
                    onClose={handleCloseColumns}
                    onChange={toggleColumn}
                    headers={headers}
                />

                {/* ADD DIALOG */}
                <ListDialog
                    mode={'add'}
                    open={addMode}
                    onClose={handleCloseAdd}
                    onSave={handleAdd}
                    item={{'list_name': '', 'list_data': []}}
                    data={[]}
                />

                {/* EDIT DIALOG */}
                <ListDialog
                    mode={'edit'}
                    open={editMode}
                    onClose={handleCloseEdit}
                    onSave={handleEdit}
                    item={selectedItem}
                />

                {/* DELETE DIALOG */}
                <DeleteDialog
                    open={deleteMode}
                    onClose={handleCloseDelete}
                    onDelete={handleDelete}
                    type={'list'}
                    row={selectedRow}
                    item={selectedItem}
                />
            </Paper>
        </div>
    )
}