import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,} from "@material-ui/core";
import { Button, Typography, makeStyles } from "@material-ui/core";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";


const useStyles = makeStyles(theme => ({
    dialog: {
        backgroundColor: '#333333',
        color: '#CCCCCC',
        '& .MuiFormLabel-root.Mui-disabled': {
            color: 'red',
        },
    },
    dialogTitleBar: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
    },
    dialogConfirm: {
        backgroundColor: '#149616',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#1be01e',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogNeutral: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#5861fc',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogWarning: {
        backgroundColor: '#d42842',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#fc0d31',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogWidget: {
        margin: '5px',
    },
    inputRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        verticalAlign: 'baseline',
    },
    primaryColor: {
        color: '#000',
        backgroundColor: '#111'
    },
    whiteColor: {
        color: "#EEE",
    },
    hide: {
        display: 'none',
    },
    spaced: {
        margin: '10px',
    }
}))

export default function DeleteDialog({ open, onClose, onDelete, type, item}) {
    const classes = useStyles()

    let name = ''
    let ref_count = null
    let ref_obj = null
    switch (type) {
        case 'alert':
            name = item?.title
            ref_count = 'NA'
            break;
        case 'analyzer':
            name = item?.pk
            ref_count = 'NA'
            break;
        case 'list':
            name = item?.list_name
            ref_count = item?.ref_count
            ref_obj = ref_count === '1' ? 'policy' : 'policies'
            break;
        case 'policy':
            name = item?.policy_name
            ref_count = item?.ref_count
            ref_obj = ref_count === '1' ? 'analyzer' : 'analyzers'
            break;
        case 'site':
            name = item?.site_name
            ref_count = item?.ref_count
            ref_obj = ref_count === '1' ? 'analyzer' : 'analyzers'
            break;
        default:
            break
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{
                width: 500
            }}
            fullWidth={true}
            maxWidth = "sm"
        >
            <DialogTitle className={classes.dialogTitleBar}>Delete {item?.pk} ?</DialogTitle>
            <DialogContent className={classes.dialog}>
                <DialogContentText className={classes.dialog}>
                      <Typography>
                      <strong>
                        {name}
                      </strong>{" "}
                          will be removed.
                    </Typography>
                    <Typography>
                        This action cannot be undone.
                    </Typography>
                    <Typography>
                        This {type} is still referenced by&nbsp;
                        <strong>
                            {ref_count}
                        </strong>
                            &nbsp;{ref_obj}.
                    </Typography>
                </DialogContentText>
                <DialogActions>
                    <Button className={classes.dialogNeutral} onClick={() => onClose()}>Cancel</Button>
                    <Button className={classes.dialogWarning} variant="contained" startIcon={<BackspaceOutlinedIcon />} onClick={() => onDelete(item?.pk)}>DELETE</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

