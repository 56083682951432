import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import {Paper} from "@mui/material";
import {makeStyles} from "@material-ui/core";
import Constants from "../utils/Constants";
import { getAccessToken } from "../utils/Auth";

let http_str = "https://"
let url = http_str.concat(Constants.apiHost, '.', Constants.domainName,'/api/v1/settings')

const useStyles = makeStyles(theme => ({
    outer_div: {
        minHeight: '100vh',
    },
}))

export const Settings = () => {
    const classes = useStyles()

    return (
        <div className={classes.outer_div} style={{padding: '20px'}}>
            <Paper variant="outlined" style={{padding: '15px'}}>
                <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                    Users and Roles
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>Users and Roles with access to this Admin Portal</p>
                        <p>Role-Based Access Controls (RBAC) are currently disabled</p>
                    </AccordionDetails>
                    <AccordionActions>
                        <Button>Cancel</Button>
                        <Button>Save</Button>
                    </AccordionActions>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                    >
                        Global
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>There are not any global settings currently.</p>
                    </AccordionDetails>
                    <AccordionActions>
                        <Button>Cancel</Button>
                        <Button>Save</Button>
                    </AccordionActions>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3-content"
                          id="panel3-header"
                        >
                            Billing
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>Billing has been disabled for this account.
                            Please contact support@ransomstop.com if you have any questions</p>
                    </AccordionDetails>
                    <AccordionActions>
                        <Button>Cancel</Button>
                        <Button>Save</Button>
                    </AccordionActions>
                </Accordion>
            </Paper>
        </div>
    )
}
