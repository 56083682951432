import React, { useEffect, useState } from "react";
import { formatDate, getTimeDeltaHuman } from "../utils/Functions";
import SimpleTable from "./SimpleTable";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { Button, Typography, TextField, InputLabel, makeStyles } from "@material-ui/core";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import Grid from "@mui/material/Unstable_Grid2";
import { ValidatedTextField, anyErrors } from "../utils/ValidatedTextField";

const useStyles = makeStyles(theme => ({
    dialog: {
        backgroundColor: '#333333',
        color: '#CCCCCC',
        '& .MuiFormLabel-root.Mui-disabled': {
            color: 'red',
        },
    },
    dialogTitleBar: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
    },
    dialogConfirm: {
        backgroundColor: '#149616',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#1be01e',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogNeutral: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#5861fc',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogWarning: {
        backgroundColor: '#d42842',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#fc0d31',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogWidget: {
        margin: '5px',
    },
    inputRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        verticalAlign: 'baseline',
    },
    primaryColor: {
        color: '#000',
        backgroundColor: '#111'
    },
    whiteColor: {
        color: "#EEE",
    },
    hide: {
        display: 'none',
    },
    spaced: {
        margin: '10px',
    }
}))

export default function ListDialog({ mode, open, onClose, onSave, item}) {
    const classes = useStyles()
    const [formData, setFormData] = useState({
        pk: '',
        list_name: '',
        description: '',
        list_type: 'file_extensions',
        list_data: '',
    })
    let title = ''

    const [error, setError] = useState([])
    const [isRequiredFieldEmpty, setIsRequiredFieldEmpty] = useState(true)

    if (mode === 'add') {
        title = 'Add List'
    }
    else if (mode === 'edit') {
        title = 'Edit: ' + item?.pk
    }

    const handleChange = (event) => {
        const { name, value } = event.target
        console.log(name + ": " + value)
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))
	};

    const handleCellChange = (e, key, column) => {
        // @TODO need to sanitize any customer provided text.  This will be used everywhere so make it a global function.
        if (key in item['list_data']) {
            if (column === 'extension') {
                item['list_data'][e.target.innerHTML] = {'description': item['list_data'][key]['description']}
                delete item['list_data'][key]
                //let new_entry = {key, {'description': item['list_data']}}
                //item['list_data'] = Object.assign(new_entry, item['list_data'])
            }
            else if (column === 'description') {
                item['list_data'][key]['description'] = e.target.innerHTML
            }
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        onSave(formData)
    }

    useEffect(() => {
        if (item) {
            setFormData({
                pk: item.pk,
                list_name: item.list_name,
                description: item.description,
                list_type: item.list_type,
                list_data: item.list_data,
            })
            console.log('item: ' + JSON.stringify(item))
            console.log('formData: ' + formData)
        }
    }, [item]);

    useEffect(() => {
        if (formData.list_name.trim() === '') {
            setIsRequiredFieldEmpty(true)
        } else {
            setIsRequiredFieldEmpty(false)
        }
    }, [formData.list_name])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            // id={item?.pk}
            sx={{
                width: 500
              }}
            fullWidth={true}
            maxWidth = "sm"
            PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries(formData.entries());
                    onClose();
                },
            }}
        >
            <DialogTitle className={classes.dialogTitleBar}>{title}</DialogTitle>
            <DialogContent className={classes.dialog}>
                <DialogContentText className={classes.dialog} component={'div'}>
                    <Grid container spacing={2}>
                        <Grid item xs={2} className={classes.inputRow}>
                            <InputLabel className={classes.dialog}>Name</InputLabel>
                        </Grid>
                        <Grid item xs={9} className={classes.inputRow}>
                            <ValidatedTextField
                                fullWidth
                                name='list_name'
                                label='Name*'
                                value={formData.list_name}
                                onChange={handleChange}
                                variant={"outlined"}
                                className={classes.dialog}
                                sx={{
                                    width: 500
                                  }}
                                inputProps={{ style: { color: "#FFF" } }}
                                InputLabelProps={{ style: { color: "#AAA" } }}
                                error={error}
                                setError={setError}
                                mode='default'
                                required='required'
                            />
                        </Grid>
                        <Grid item xs={2} className={classes.inputRow}>
                            <InputLabel className={classes.dialog}>Description</InputLabel>
                        </Grid>
                        <Grid item xs={9} className={classes.inputRow}>
                            <ValidatedTextField
                                fullWidth
                                name='description'
                                label='Description'
                                value={formData.description}
                                onChange={handleChange}
                                variant={"outlined"}
                                className={classes.dialog}
                                sx={{
                                    width: 500
                                  }}
                                inputProps={{ style: { color: "#FFF" } }}
                                InputLabelProps={{ style: { color: "#AAA" } }}
                                error={error}
                                setError={setError}
                                mode='default'
                            />
                        </Grid>
                    </Grid>
                    <SimpleTable
                        mode={mode}
                        headers={['extension', 'description']}
                        item={item}
                        handleCellChange={handleCellChange}
                    />
                    <Typography variant='subtitle2' className={classes.dialogWidget}>Item Count: {item ? item.item_count : 0}</Typography>
                    <Typography variant='subtitle2' className={classes.dialogWidget}>Created: {formatDate(item ? item.created : new Date().getTime() / 1000)} ({getTimeDeltaHuman(item ? item.created : new Date())})</Typography>
                    <Typography variant='subtitle2' className={classes.dialogWidget}>Modified: {formatDate(item ? item.modified : new Date().getTime() / 1000)} ({getTimeDeltaHuman(item ? item.modified : new Date())})</Typography>
                    <Typography variant='subtitle2' className={classes.dialogWidget}>Used: {item ? item.ref_count : 0}</Typography>
                </DialogContentText>
                <DialogActions>
                    <Button className={classes.dialogNeutral} onClick={() => {setError([]); onClose()} }>Cancel</Button>
                    <Button className={classes.dialogConfirm} onClick={(event) => handleSubmit(event)} variant="contained" startIcon={<CheckBoxOutlinedIcon />} disabled={!anyErrors(error) || isRequiredFieldEmpty}>SAVE</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

