import React, { useEffect, useState } from 'react'
import axios from "axios";
import Constants from '../utils/Constants'
import { getAccessToken } from "../utils/Auth";
import Notification from "./Notification"
import { formatDate, formatFileList, isHidden, getDetail } from "../utils/Functions";
import useSWR from "swr";
import { IconButton, Stack } from "@mui/material";
import { Box, MenuItem, Select, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import ViewWeekOutlinedIcon from "@mui/icons-material/ViewWeekOutlined";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import ColumnsDialog from "./ColumnsDialog";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import DeleteDialog from "./DeleteDialog";
import AlertDialog from "./AlertDialog";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';


let http_str = "https://"
let base_url = http_str.concat(Constants.apiHost, '.', Constants.domainName,'/api/v1/alerts')

const useStyles = makeStyles(theme => ({
    card: {
        backgroundColor: '#424242',
        color: '#EEEEEE',
    },
    itemBar: {
        justifyContent: 'flex-end',
        display: 'flex',
        marginRight: '0',
        padding: '15px',
        backgroundColor: '#222222',
    },
    pageBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        backgroundColor: '#222222',
        color: '#AAAAAA',
    },
    tableComponent: {
        borderStyle: 'solid',
        borderColor: '#000',
        borderRadius: '10px',
        borderSpacing: '0px',
        borderRightWidth: '0px',
        borderTopWidth: '1px',
        borderBottomWidth: '0px',
        borderLeftWidth: '0px',
        // backgroundColor: '#222222' // setting this breaks row highlighting, leaving this as a warning
    },
    tableComponentHighlight: {
        fontWeight: 'bolder',
        borderStyle: 'solid',
        borderColor: '#000',
        borderRadius: '10px',
        borderSpacing: '0px',
        borderRightWidth: '0px',
        borderTopWidth: '1px',
        borderBottomWidth: '0px',
        borderLeftWidth: '0px',
        // backgroundColor: '#222222' // setting this breaks row highlighting, leaving this as a warning
    },
    tableCell: {
        paddingLeft: '12px',
    },
    clickableTableCell: {
        paddingLeft: '12px',
        cursor: 'pointer',
    },
    dialog: {
        backgroundColor: '#333333',
        color: '#CCCCCC',
        margin: '10',
    },
    dialogTitleBar: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
    },
    dialogConfirm: {
        backgroundColor: '#149616',
        color: '#CCCCCC',
    },
    dialogNeutral: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
    },
    dialogWarning: {
        backgroundColor: '#d42842',
        color: '#CCCCCC',
    },
    primaryColor: {
        color: '#000',
        backgroundColor: '#111'
    },
    whiteColor: {
        color: "#EEE",
    },
    hide: {
        display: 'none',
    },
    spaced: {
        margin: '10px',
    }
}))

const fetcher = (...args) => fetch(...args).then((res) => res.json());


const headers = [
    { field: 'acked', headerName: 'Acked', width: 50, hidden: false },
    { field: 'pk', headerName: 'ID', width: 100, hidden: true },
    { field: 'title', headerName: 'Title', width: 250, hidden: false },
    { field: 'fut.filename', headerName: 'Filename', width: 200, hidden: false },
    { field: 'fut.location', headerName: 'Location', width: 200, hidden: false },
    { field: 'fut.remote_location', headerName: 'Share Path', width: 200, hidden: true },
    { field: 'affected_files', headerName: 'Affected Files', width: 150, hidden: true },
    { field: 'node_name', headerName: 'Reporter', width: 125, hidden: true },
    { field: 'node_ip', headerName: 'Reporter IP', width: 100, hidden: true },
    { field: 'description', headerName: 'Description', width: 300, hidden: true },
    { field: 'alert_type', headerName: 'Type', width: 100, hidden: true },
    { field: 'confidence', headerName: 'Confidence', width: 75, hidden: false },
    { field: 'created', headerName: 'Created', width: 200, hidden: false, },
    { field: 'modified', headerName: 'Modified', width: 200, hidden: true, },
    { field: 'edit', headerName: 'Edit', width: 50, hidden: true },
    { field: 'delete', headerName: 'Delete', width: 50, hidden: false },
];

export default function AlertCard() {
    const classes = useStyles()
    const [items, setItems] = useState([])
    const [selectedItem, setSelectedItem] = useState(null)
    const [selectedRow, setSelectedRow] = useState(null)
    const [columnsMode, setColumnsMode] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [deleteMode, setDeleteMode] = useState(false)
    const [filter, setFilter] = React.useState('all')
    const [limit, setLimit] = React.useState(10)
    const [pages, setPages] = useState([''])
    const [pageIndex, setPageIndex] = useState(0)
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    //const {
    //    items,
    //    error,
    //    isValidating,
    //} = useSWR(url, fetcher);
    //if (error) return <div className='failed'>failed to load</div>;
    //if (isValidating) return <div className="Loading">Loading...</div>;


    const getItems = async () => {
        let url = base_url
        if (pageIndex !== 0) {
            console.log('fetch page: ' + pages[pageIndex])
            // sort and order are no longer support (sort is automatic)
            // we may want to add it back in to sort by a different field or reverse sort
            url = url + '?limit=' + limit + '&filter=' + filter + '&nextPageKey=' + pages[pageIndex]
        }
        else {
            url = url + '?limit=' + limit + '&filter=' + filter
        }
        const http_headers = { "Content-Type": "application/json","Authorization": await getAccessToken() }
        console.log('url: ' + url)
        // console.log('headers: ' + JSON.stringify(http_headers))
        await axios
            .get(url, { headers: http_headers })
            .then(res => {
                if (pageIndex === pages.length - 1) {
                    let npk = res.data['nextPageKey']
                    if (npk) {
                        let tmp_arr = pages
                        tmp_arr.push(npk)
                        setPages(tmp_arr)
                    }
                }
                const items = res.data['alerts'];
                setItems(items)
            })
            .catch(err => {
                let error_response = 'unknown error'
                if (err.response) {
                    error_response = err.response['data']
                    console.log('bad response from server: ' + JSON.stringify(err.response))
                } else if (err.request) {
                    error_response = err.request
                  console.log('no response from server: ' + JSON.stringify(err.request))
                } else {
                    error_response = err
                  console.log(err)
                }
                Notification(`Error while fetching ${url}. ${error_response}`, 'error');
            })
    }

    const editItem = async (item) => {
        let url = base_url + "/" + item.pk
        const http_headers = { 'Content-Type': 'application/json','Authorization': await getAccessToken() }
        let params = {
            pk: item.pk,
            title: item.title,
            notes: item.notes,
            acked: item.acked,
        }
        await axios
            .put(url, { 'alert': params },{ headers: http_headers })
            .then(res => {
                console.log(res.data);
                // Add the data submitted back to the local table
                // setItems(items.concat([params]))
                Notification(res.data['msg'], 'info');
                forceUpdate()
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + err.response)
                } else if (err.request) {
                  console.log('no response from server: ' + err)
                } else {
                  console.log(err)
                }
                Notification(`Error updating alert. ${err}`, 'error');
            })
    }

    const deleteItem = async (uuid) => {
        let url = base_url + "/" + uuid
        const headers = { 'Content-Type': 'application/json','Authorization': await getAccessToken() }
        await axios
            .delete(url, { headers: headers })
            .then(res => {
                let items_filtered = items.map((x) => x)
                items_filtered = items_filtered.filter(i => i.pk !== uuid)
                setItems(items_filtered)
                Notification(res.data['msg'], 'info');
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + err.response)
                } else if (err.request) {
                  console.log('no response from server: ' + err)
                } else {
                  console.log(err)
                }
                Notification(`Error: deleting ${uuid}. ${err}`, 'error');
            })
    }

    // PrefetchSelectedRow will fetch list details every row you click
    // It should make loading the edit screen faster, but it might add a lot more API calls
    const prefetchSelectedRow = (uuid) => {
        if (selectedRow === uuid) {
            // deselect a row if it's already selected
            setSelectedRow(null)
        }
        else {
            setSelectedRow(uuid)
            setSelectedItem(getItemByPk(uuid))
            //getDetail(uuid)
        }
    }

    function getItemByPk(pk) {
        return items.find(i => i.pk === pk)
    }

    function getAckedIcon(acked) {
        return acked === true ? <CheckCircleOutlineOutlinedIcon color={"success"}/> : <ErrorOutlineOutlinedIcon color={"error"}/>
    }

    const handleOpenColumns = () => {
        setColumnsMode(true);
    };

    const handleOpenEdit = (e, uuid) => {
        setSelectedRow(uuid)
        console.log("edit " + selectedRow)
        getDetail(base_url, 'alert', uuid, setSelectedItem, setEditMode)
    };

    const handleEdit = (fields) => {
        let editData = editItem(fields)
        setEditMode(false)
    }

    const handleCloseEdit = () => {
        setEditMode(false);
    }

    const handleOpenDelete = (e, uuid) => {
        //e.stopPropagation()
        setSelectedRow(uuid)
        setDeleteMode(true);
        console.log("delete " + selectedRow)
    };

    const handleDelete = (uuid) => {
        deleteItem(uuid)
        setDeleteMode(false);
    };

    const handleCloseDelete = () => {
        setDeleteMode(false);
    };

    const handleCloseColumns = () => {
        setColumnsMode(false);
    };

    const handleFilter = (event) => {
        setFilter(event.target.value);
    };

    const handleLimit = (event) => {
        setLimit(event.target.value);
        setPages([0])
        setPageIndex(0)
    };

    const handlePreviousPage = () => {
        if (pageIndex > 0) {
            setPageIndex(pageIndex - 1)
        }
    }

    const handleNextPage = () => {
        if (pageIndex < pages.length - 1) {
            setPageIndex(pageIndex + 1)
        }
    }

    const toggleColumn = (event) => {
        for (let i = 0; i < headers.length; i++) {
            if (headers[i]['field'] === event.target.id) {
                headers[i]['hidden'] = !headers[i]['hidden']
            }
        }
        //  ugly but since the checkboxes aren't tied to state, they are tied to column definitions, they don't update
        forceUpdate()
    };

    useEffect(() => {
        getItems()
    }, [pageIndex, limit, filter])


    return (
        <>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant="h4">Alerts</Typography>
                <Box
                    sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
                >
                    Filter:&nbsp;
                    <Select
                        classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                        labelId="filter-select"
                        id="filter-select"
                        value={filter}
                        label="Filter"
                        onChange={handleFilter}
                    >
                      <MenuItem value={'all'}>All</MenuItem>
                      <MenuItem value={'unacked'}>Unacknowledged</MenuItem>
                      <MenuItem value={'highcon'}>High Confidence</MenuItem>
                      <MenuItem value={'acked'}>Acknowledged</MenuItem> {/* New Option */}
                      <MenuItem value={'lowcon'}>Low Confidence</MenuItem> {/* New Option */}

                    </Select>
                    <ViewWeekOutlinedIcon className={classes.spaced} color={"primary"}
                                          onClick={() => handleOpenColumns()}/>
                </Box>
            </Stack>
            {/* TABLE */}
            <table className={classes.tableComponent} style={{width: 100 + "%"}} >
                <thead>
                    <tr key='1' className={classes.tableComponent} >
                        {headers.map(header => (
                            <th key={header.field} width={header.width} className={ header.hidden === true ? classes.hide: '' }>{header.headerName}</th>
                            ))}
                    </tr>
                </thead>
                <tbody  className={classes.tableComponent} >
                    {items.map(val =>
                        <tr key={val.pk} onClick={() => prefetchSelectedRow(val.pk)}
                            bgcolor={selectedRow === val.pk ? '#242c42' : '#222222'}
                            className={val.acked ? classes.tableComponentHighight : classes.tableComponent}>
                            <td className={isHidden('acked', headers) ? classes.hide : classes.clickableTableCell}
                                onClick={(e) => handleOpenEdit(e, val.pk)}>
                                {getAckedIcon(val.acked)}
                            </td>
                            <td className={isHidden('pk', headers) ? classes.hide : classes.tableCell}>{val.pk}</td>
                            <td className={isHidden('title', headers) ? classes.hide : classes.tableCell}>{val.title}</td>
                            <td className={isHidden('fut.filename', headers) ? classes.hide : classes.tableCell}>{val.fut.filename}</td>
                            <td className={isHidden('fut.location', headers) ? classes.hide : classes.tableCell}>{val.fut.location}</td>
                            <td className={isHidden('affected_files', headers) ? classes.hide : classes.tableCell}>{formatFileList(val.affected_files)}</td>
                            <td className={isHidden('node_name', headers) ? classes.hide : classes.tableCell}>{val.node_name}</td>
                            <td className={isHidden('node_ip', headers) ? classes.hide : classes.tableCell}>{val.node_ip}</td>
                            <td className={isHidden('description', headers) ? classes.hide : classes.tableCell}>{val.description}</td>
                            <td className={isHidden('alert_type', headers) ? classes.hide : classes.tableCell}>{val.alert_type}</td>
                            <td className={isHidden('confidence', headers) ? classes.hide : classes.tableCell}>{val.confidence}</td>
                            <td className={isHidden('created', headers) ? classes.hide : classes.tableCell}>{formatDate(val.created)}</td>
                            <td className={isHidden('modified', headers) ? classes.hide : classes.tableCell}>{formatDate(val.modified)}</td>
                            <td className={isHidden('edit', headers) ? classes.hide : classes.clickableTableCell}>
                                <EditOutlinedIcon color={"primary"} onClick={(e) => handleOpenEdit(e, val.pk)}/>
                            </td>
                            <td className={isHidden('delete', headers) ? classes.hide : classes.clickableTableCell}>
                                <BackspaceOutlinedIcon color={"error"} onClick={(e) => handleOpenDelete(e, val.pk)}/>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <Stack className={classes.pageBar}
               elevation={0}
                direction="row"
                alignItems="center"
                sx={{ width: 1, height: "5vh" }} >
                    <IconButton onClick={() => getItems()}><RefreshOutlinedIcon className={classes.spaced} /></IconButton>
                    <Typography className={classes.spaced}>Rows per page:</Typography>
                    <Select
                        classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                        labelId="fetch-limit-select"
                        id="fetch-limit-select"
                        value={limit}
                        label="Limit"
                        onChange={handleLimit}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>
                    <IconButton disabled={pageIndex === 0} onClick={() => handlePreviousPage()}><ChevronLeftOutlinedIcon /></IconButton>
                    <Typography variant={'h6'}>{pageIndex + 1}</Typography>
                    <IconButton disabled={pageIndex >= pages.length - 1} onClick={() => handleNextPage()}><ChevronRightOutlinedIcon /></IconButton>
                </Stack>

            {/* DIALOGS */}
            {/* COLUMNS DIALOG */}
            <ColumnsDialog
                variant={"outlined"}
                open={columnsMode}
                onClose={handleCloseColumns}
                onChange={toggleColumn}
                headers={headers}
            />

            {/* EDIT DIALOG */}
            <AlertDialog
                mode={'edit'}
                open={editMode}
                onClose={handleCloseEdit}
                onSave={handleEdit}
                item={selectedItem}
            />

            {/* DELETE DIALOG */}
            <DeleteDialog
                open={deleteMode}
                onClose={handleCloseDelete}
                onDelete={handleDelete}
                type={'alert'}
                row={selectedRow}
                item={selectedItem}
            />
        </>
    )
}