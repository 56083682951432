import React from "react";
import { logout, authenticated } from '../utils/Auth';
import { makeStyles, Box, Typography, AppBar, Toolbar, Dialog } from '@material-ui/core'
import { IconButton, Stack } from "@mui/material";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appbar: {
        width: '100%',
        elevation: '5',
        zIndex: theme.zIndex.drawer + 1
    },
    toolbar: {
        height: '75px',
    },
    logoDiv: {
      display: 'flex',
      width: '500px',
    },
    profileBar: {
        display: 'flex',
        marginLeft: 'auto',
        alignItems: 'center',
        padding: '10px',
    },
    navLabel: {
        fontWeight: 'normal',
        textDecoration: 'none',
        fontSize: '1.4rem',
        alignItems: 'center',
        justifyContent: 'center',
        lineHeight: '20px',
        display: 'flex',
        padding: '10px',
    },
    navDiv: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '10px',
        '&:hover': {
            background: "#222",
        },
    },
    divider: {
      background: '#bbbbbb',
    },
    versionLabel: {
        padding: '10px',
        top: '20px',
        color: '#5e5e5e',
    },
    spaced: {
        margin: '10px',
    },
    greeting: {
        width: 500,
        height: 200,
        display: 'flex',
        direction: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // borderRadius: '40px',
        backgroundColor: '#5e5e5e',
        color: '#bbbbbb',
    },
}))

export default function Logout() {
    const classes = useStyles()

    if (authenticated()) {
        return (
            <div id='root' className={classes.root}>
                <AppBar position="fixed" className={classes.appbar}>
                    <Toolbar className={classes.toolbar}>
                        <Box className={classes.logoDiv}>
                            <img src="/red-logo_white-text.png" alt="logo" height="45px"/>
                        </Box>
                        <Box className={classes.profileBar}>
                            <IconButton size={"large"}><HelpOutlineRoundedIcon/></IconButton>
                            <a href={localStorage.getItem('href')}><PowerSettingsNewOutlinedIcon color={'error'}/></a>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Dialog fullscreen open={true}>
                    <Stack className={classes.greeting}>
                        <Typography variant={'h5'}>Press Button To Log Out</Typography>
                        <IconButton onClick={logout}><PowerSettingsNewOutlinedIcon color={'error'} /></IconButton>
                        <Typography variant={'h5'}>Or Return To The Dashboard</Typography>
                        <a href='/' ><HomeOutlinedIcon color={'primary'}/></a>
                    </Stack>
                </Dialog>
            </div>
        )
    }
    else {
        return (
            <div id='root' className={classes.root}>
                <AppBar position="fixed" className={classes.appbar}>
                    <Toolbar className={classes.toolbar}>
                        <Box className={classes.logoDiv}>
                            <img src="/red-logo_white-text.png" alt="logo" height="45px"/>
                        </Box>
                        <Box className={classes.profileBar}>
                            <IconButton size={"large"}><HelpOutlineRoundedIcon/></IconButton>
                            <a href={localStorage.getItem('href')}><PowerSettingsNewOutlinedIcon color={'error'}/></a>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Dialog fullscreen open={true}>
                    <Stack className={classes.greeting}>
                        <Typography variant={'h5'}>You Are Logged Out of the RansomStop Admin Portal</Typography>
                        <br/>
                        <Typography variant={'h5'}>Press Button To Return To The Homepage</Typography>
                        <br />
                        <a href='/' ><HomeOutlinedIcon /></a>
                    </Stack>
                </Dialog>
            </div>
        )
    }
}