import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup } from "@material-ui/core";
import { Button, Checkbox, makeStyles } from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    dialog: {
        backgroundColor: '#333333',
        color: '#CCCCCC',
        '& .MuiFormLabel-root.Mui-disabled': {
            color: 'red',
        },
    },
    dialogTitleBar: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
    },
    dialogConfirm: {
        backgroundColor: '#149616',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#1be01e',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogNeutral: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#5861fc',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogWarning: {
        backgroundColor: '#d42842',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#fc0d31',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogWidget: {
        margin: '5px',
    },
    inputRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        verticalAlign: 'baseline',
    },
    primaryColor: {
        color: '#000',
        backgroundColor: '#111'
    },
    whiteColor: {
        color: "#EEE",
    },
    hide: {
        display: 'none',
    },
    spaced: {
        margin: '10px',
    }
}))

export default function ColumnsDialog({ open, onClose, onChange, headers}) {
    const classes = useStyles()

    return (
        <Dialog
            variant={"outlined"}
            open={open}
            onClose={onClose}
            sx={{
                width: 200
              }}
            maxWidth = "xs"
        >
            <DialogTitle className={classes.dialogTitleBar}>Display Columns</DialogTitle>
                <DialogContent className={classes.dialog}>
                    <DialogContentText className={classes.dialog} component={'span'}>
                        <FormGroup>
                            {headers.map((header, idx) => (
                                  <FormControlLabel
                                      control={<Checkbox
                                          checked={ ! header.hidden }
                                          onChange={onChange}
                                          style ={{
                                            color: "#4e54bf",
                                            }}
                                          id={header.field}
                                          key={idx} />}
                                      label={header['headerName']} />
                            ))}
                        </FormGroup>
                    </DialogContentText>
                    <DialogActions>
                        <Button  className={classes.dialogNeutral} variant={'contained'} onClick={() => onClose()}>Close</Button>
                    </DialogActions>
                </DialogContent>
        </Dialog>
    )
}

